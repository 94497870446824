import React from "react";
import RecordSection from "../home/record-section";
import Breadcrumbs from "../../reusebale/breadcrumbs"
import { useSelector } from "react-redux"
import ProfileInfo from "./profileInfo"

const ViewProfile = ({onBack}) => {
  const { loginSuccess } = useSelector((state) => state.auth)
  return (
    <div className="mt-5">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        subheading="My Account"
        content={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName} `}
        onBack={onBack}
      />
      <RecordSection />
      <ProfileInfo/>
    </div>
  );
};

export default ViewProfile;
