
import { CreatePropertyStepOneAction } from "../../actions/property/PropertyAction"
import { ChangePropertyCreateStepsActionConst, createPropertyActionConst, CreateNewPropertyActionConst, GetAllPropertiesByLandlord, ChangePropertyStatusActionConst } from "../../constants/PropertyActionConst"
const initialState = {
    loader: false,
    allProperties: [],
    propertyCreateStep: 1,
    createNewPropertyBody: {
        propertyName: null,
        propertyName: null,
        landlordId: null,
        category: null,
        type: null,
        completeAddress: null,
        streetOne: null,
        streetTwo: null,
        city: null,
        country: null,
        state: null,
        images: [],
        thumbnailImage: null,
        longitude: null,
        latitude: null,
        zipCode: null,
        unitCreationFields: null,
        unitData: [],


    },
    createNewPropertySucc: null,
    createNewPropertyError: null,



}
export default (state = initialState, action) => {
    switch (action.type) {
        case ChangePropertyCreateStepsActionConst.CHANGE_PROPERTY_STEPS:
            state = {
                ...state,
                propertyCreateStep: action.step,
            }
            break


        case createPropertyActionConst.CREATE_PROPERTY_STEP_ONE:
            let stepOneFiels = {
                ...state.createNewPropertyBody,
                category: action.data.category,
                type: action.data.type

            }
            state = {
                ...state,
                createNewPropertyBody: stepOneFiels
            }
            break

        case createPropertyActionConst.CREATE_PROPERTY_STEP_TWO:
            let stepTwoFiels = {
                ...state.createNewPropertyBody,
                propertyName: action.data.propertyName,
                completeAddress: action.data.compleAddress,
                streetOne: action.data.streetOne,
                streetTwo: action.data.streetTwo,
                city: action.data.city,
                country: action.data.country,
                state: action.data.state,
                images: action.data.imagesList,
                longitude: action.data.langitude,
                latitude: action.data.latitude,
                zipCode: action?.data?.zipCode,
                thumbnailImage: action?.data?.thumbNailImage

            }

            state = {
                ...state,
                createNewPropertyBody: stepTwoFiels
            }
            break

        case createPropertyActionConst.CREATE_PROPERTY_STEP_THREE:
            let stepThreeFiels = {
                ...state.createNewPropertyBody,
                unitData: action.unitData,
                unitCreationFields: action.unitCreationFields


            }
            state = {
                ...state,
                createNewPropertyBody: stepThreeFiels
            }
            break

        //Create New Property

        case CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_REQ:

            state = {
                ...state,
                loader: true,
                createNewPropertySucc: null,
                createNewPropertyError: null
            }
            break


        case CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_SUCC:

            state = {
                ...state,
                loader: false,
                createNewPropertySucc: action.success,
                createNewPropertyError: null,

                createNewPropertyBody: {
                    propertyName: null,
                    propertyName: null,
                    landlordId: null,
                    category: null,
                    type: null,
                    completeAddress: null,
                    streetOne: null,
                    streetTwo: null,
                    city: null,
                    country: null,
                    state: null,
                    images: [],
                    thumbnailImage: null,
                    longitude: null,
                    latitude: null,
                    zipCode: null,
                    unitCreationFields: null,
                    unitData: [],

                }

            }
            break


        case CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_FAIL:

            state = {
                ...state,
                loader: false,
                createNewPropertySucc: null,
                createNewPropertyError: action.error
            }
            break

        case CreateNewPropertyActionConst.CREATE_NEW_PROPERTY_CLEAR:
            state = {
                ...state,
                loader: false,
                createNewPropertySucc: null,
                createNewPropertyError: null
            }
            break

        //Get All Properties by landlord
        case GetAllPropertiesByLandlord.GET_ALL_PROPERTIES_REQ:
            state = {
                ...state,
                loader: true,

            }
            break

        case GetAllPropertiesByLandlord.GET_ALL_PROPERTIES_SUCC:
            state = {
                ...state,
                loader: false,
                allProperties: action.property

            }
            break
        case GetAllPropertiesByLandlord.GET_ALL_PROPERTIES_FAIL:
            state = {
                ...state,
                loader: false,


            }
            break


        //Change Proeprty Status

        case ChangePropertyStatusActionConst.CHANGE_PROPERTY_STATUS_REQ:

            state = {
                ...state,
                loader: true,

            }
            break

        case ChangePropertyStatusActionConst.CHANGE_PROPERTY_STATUS_SUCC:
            let properties = [...state.allProperties]
            for (let i = 0; i < properties.length; i++) {
                if (properties[i]._id === action.data.id) {
                    properties[i].status = action.data.status
                }

            }
            state = {
                ...state,
                loader: false,
                allProperties: properties
            }
            break

        case ChangePropertyStatusActionConst.CHANGE_PROPERTY_STATUS_FAIL:

            state = {
                ...state,
                loader: false,

            }
            break








    }
    return state

}