import React from "react";
import ParagraphTag from "../../reusebale/Paragraph"
import HeadingTag from "../../reusebale/heading"
import { Search } from "lucide-react";
import ReportCard from "./report-card";
import db from "../../../db/index.json";


const ReportsSection = () => {
  const { reports } = db;


  return (
    <div className="w-full">
      <div className="flex justify-between items-center pt-4">
        <div className="w-[70%]">
          <HeadingTag
            title='Reports'
            classes="font-popinsSemiBold text-black text-xl mt-[-4px]"
          />
          <ParagraphTag
            content='Renew or end leases that are expiring soon.'
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
        <div className="w-[30%]">
          <div className="flex justify-between items-center">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full font-popinsRegular mt-1 bg-[#E6BF9F4D] pl-10 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
              <div className="absolute top-[14px] left-4">
                <Search color="#1A1A1A5D" size={15} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between mt-3">
        <div className="w-[49%]">
            <ReportCard item={reports.favourites.rating} title={reports.favourites.title}/>
            <ReportCard item={reports.leasing.rating} title={reports.leasing.title}/>
            <ReportCard item={reports.whatYouOwe1.rating} title={reports.whatYouOwe1.title}/>
        </div>
        <div className="w-[49%]">
            <ReportCard item={reports.businessOverview.rating} title={reports.businessOverview.title}/>
            <ReportCard item={reports.Tenants.rating} title={reports.Tenants.title}/>
            <ReportCard item={reports.whatYouOwe2.rating} title={reports.whatYouOwe2.title}/>
        </div>
      </div>
    </div>
  );
};

export default ReportsSection;
