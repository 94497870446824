import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import { Phone,Pencil } from "lucide-react";
import ImageTag from "../../reusebale/imageTag";

const TenantsSection = () => {
  const { rightPanel } = db;

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="flex justify-between items-center ">
        <ParagraphTag
          content={rightPanel.tenants}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.viewall}
          classes="text-themeColor font-popinsSemiBold text-sm cursor-pointer"
        />
      </div>
      <div className="flex border-b-[1px] border-darkColor/40 pb-1 mt-6">
        <div className="w-[45%]">
          <ParagraphTag
            content={rightPanel.tenants}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[29%] text-end">
          <ParagraphTag
            content={rightPanel.leased}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-end">
          <ParagraphTag
            content={rightPanel.quick}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
      </div>
      {rightPanel.tenantsTable.map((item, i) => (
        <div className="flex mt-4 justify-between items-center">
          <div className="w-[45%] flex items-center">
            <div className="w-[25%]">
              <ImageTag
                path={item.imagePath}
                classes="w-full"
                altText="login"
              />
            </div>
            <div className="w-[75%] pl-1">
              <ParagraphTag
                content={item.rowDesc}
                classes="text-darkColor/40 font-popinsMedium text-xs"
              />
              <ParagraphTag
                content={item.rowName}
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
            </div>
          </div>
          <div className="w-[34%] text-center">
            <ParagraphTag
                content={item.propertyId}
                classes='text-darkColor/40 font-popinsMedium text-xs'
              />
            <ParagraphTag
                content={item.propertyName}
                classes='text-darkColor font-popinsMedium text-sm'
              />
          </div>
          <div className="w-[19%] flex justify-between items-center">
            <div className="flex justify-center items-center bg-white border-2 border-themeColor w-[45%] h-9 rounded-lg cursor-pointer">
                <Phone color="#FF814E" size={20} fill="#FF814E"/>
            </div>
            <div className="flex justify-center items-center bg-themeColor w-[45%] h-9 rounded-lg cursor-pointer">
                <Pencil color="#FFFF" size={20}/>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TenantsSection;
