import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from '../../reusebale/imageTag'
import db from "../../../db/index.json";
import { Plus } from "lucide-react";

const BroadCastSection = ({ onViewDetails }) => {
    const { broadcasts } = db;

  return (
    <div className="bg-white mt-6 w-full rounded-lg">
        <div className="w-[95%] mx-auto">
          <div className="flex justify-between py-5">
            <div className="w-[70%]">
              <ParagraphTag
                content={broadcasts.heading}
                classes="text-darkColor font-popinsSemiBold text-lg"
              />
            </div>

            <div className="w-[30%] flex justify-end">
              <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2">
                <Plus color="#FFFFFF" size={15} />
                <ParagraphTag
                  content='New Broadcasts'
                  classes="text-white font-popinsSemiBold text-xs pl-2"
                />
              </button>
            </div>
          </div>
          <div>
            {broadcasts.broadCastData.map((item, i) => (
              <div className="flex" onClick={onViewDetails}>
                <div className="w-[80%] py-2 flex items-center">
                  <ImageTag classes={`w-[38px] h-[38px]`} path={'/assets/daashboard/notification-color.png'} altText='logo' />
                  <div className="px-2">
                    <ParagraphTag
                      content={item.heading}
                      classes={`text-darkColor font-popinsSemiBold text-xs `}
                      />

                  </div>
                </div>
                <div className="w-[20%]">
                  <div className="px-4 py-4">
                    <ParagraphTag
                      content={item.description}
                      classes={`text-darkColor font-popinsSemiBold text-xs `}
                    />

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default BroadCastSection;
