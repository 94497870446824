import React from "react";
import HeadingTag from '../../component/reusebale/heading'
import ParagraphTag from '../../component/reusebale/Paragraph'
import ForgetFormSection from '../../component/forget-password/formSection'
import db from '../../db/index.json'
import ImageTag from '../../component/reusebale/imageTag'

function ForgetPassword() {
  const { login } = db;



  return (
    <div className="lg:h-screen flex items-center py-12 lg:py-0">
      <div className="flex w-[90%] mx-auto lg:h-[90vh] rounded-xl">
        <div className="w-[50%] h-full hidden lg:block">
          <ImageTag path='/assets/auth/auth-image.png' classes='w-full h-full' altText='login' />
        </div>
        <div className="lg:w-[50%] w-full h-full my-auto flex flex-col items-center justify-center bg-white">
          <div className="w-[80%] mx-auto flex flex-col items-center justify-center">
            <ImageTag path='/assets/logo.png' classes='w-full lg:w-[55%] h-40' altText='logo' />
            <div className="w-full pt-1 h-full">
              <HeadingTag title='Forgot Password' classes='font-popinsSemiBold text-themeColor text-3xl' />
              <ParagraphTag content="Enter your email address we'll send an OTP" classes='text-paraColor text-sm pt-2 font-popinsRegular' />
              <ForgetFormSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
