import React from "react";
import ImageTag from "../reusebale/imageTag";
import ParagraphTag from "../reusebale/Paragraph";

const IconCards = (props) => {

  const { path, mainClasses, imgClasses, title, titleClasses } = props;

  return (
    <div className={`${mainClasses} bg-[#FAF2EC] flex justify-center rounded-lg items-center cursor-pointer hover:border-2 hover:border-themeColor`}>
      <div className="flex flex-col items-center">
        <ImageTag
          path={path}
          classes={imgClasses}
          altText="login"
        />
        <ParagraphTag
          content={title}
          classes={`${titleClasses} pt-6 text-xs font-popinsMedium`}
        />
      </div>
    </div>
  );
};

export default IconCards;
