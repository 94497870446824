import React from "react";


const ParagraphTag=(props) => {
  const { content, classes } = props;

  return (
    <h1 className={`${classes}`}>{content}</h1>
  );
}

export default ParagraphTag;
