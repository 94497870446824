export const GetAllPropertiesByLandlord = {
    GET_ALL_PROPERTIES_REQ: "GET_ALL_PROPERTIES_REQ",
    GET_ALL_PROPERTIES_SUCC: "GET_ALL_PROPERTIES_SUCC",
    GET_ALL_PROPERTIES_FAIL: "GET_ALL_PROPERTIES_FAIL"
}




export const CreateNewPropertyActionConst = {
    CREATE_NEW_PROPERTY_REQ: "CREATE_NEW_PROPERTY_REQ",
    CREATE_NEW_PROPERTY_SUCC: "CREATE_NEW_PROPERTY_SUCC",
    CREATE_NEW_PROPERTY_FAIL: "CREATE_NEW_PROPERTY_FAIL",
    CREATE_NEW_PROPERTY_CLEAR: "CREATE_NEW_PROPERTY_CLEAR"
}



export const ChangePropertyCreateStepsActionConst = {
    CHANGE_PROPERTY_STEPS: "CHANGE_PROPERTY_STEPS"
}

export const createPropertyActionConst = {
    CREATE_PROPERTY_STEP_ONE: "CREATE_PROPERTY_STEP_ONE",
    CREATE_PROPERTY_STEP_TWO: "CREATE_PROPERTY_STEP_TWO",
    CREATE_PROPERTY_STEP_THREE: "CREATE_PROPERTY_STEP_THREE"
}


export const ChangePropertyStatusActionConst = {
    CHANGE_PROPERTY_STATUS_REQ: "CHANGE_PROPERTY_STATUS_REQ",
    CHANGE_PROPERTY_STATUS_SUCC: "CHANGE_PROPERTY_STATUS_SUCC",
    CHANGE_PROPERTY_STATUS_FAIL: "CHANGE_PROPERTY_STATUS_FAIL"
}
export const GetPropertyMasterDataActionConst = {
    GET_PROPERTY_MASTERDATA: "GET_PROPERTY_MASTERDATA"
}