import React, { useEffect, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import * as PropertyAction from "../../../../store/actions/property/PropertyAction";
import BaseUrl from "../../../../constants/BaseUrl";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import { useDispatch, useSelector } from "react-redux";
import MyLoader from "../../../reusebale/myLoader";
import db from "../../../../db/index.json";
import UnitsCard from "../../../reusebale/uniCard";
import EditUnits from "../../../popup/editUnits"
import { Plus } from "lucide-react";

const PropertyUnits = () => {
  const { properties } = db;
  const dispatch = useDispatch();
  const { createNewPropertyBody } = useSelector((state) => state.property);
  const [isHVAC, setIsHVAC] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleCheckboxChange = () => {
    setIsHVAC((prevState) => !prevState);
    setUnitFormFields({
      ...unitFormFields,
      hvac: !unitFormFields.hvac
    })
  };

  const openPopup = () => {
    setIsEdit(true);
  };

  const closePopup = () => {

    setIsEdit(false);
  };



  const [unitFormFields, setUnitFormFields] = useState({
    numberOfUnits: 0,
    totalBuilding: 0,
    rent: 0,
    rentType: 0,
    offices: 0,
    bathrooms: 0,
    size: 0,
    buildingDepth: 0,
    clearHeight: 0,
    parkingRation: 0,
    sprinklers: 0,
    water: 0,
    electric: 0,
    waltRollArea: 0,
    numberOfSuites: 0,
    lodingDocks: 0,
    gradeLevelDoors: 0,
    tenantSize: 0,
    yearBuild: 0,
    hvac: false,
    hvacValue: 0,
    unitList: []
  })

  function GenerateUnits(count) {
    const units = [];
    for (let i = 1; i <= count; i++) {
      units.push({
        id: i,
        unitName: `Unit ${i}`,
        totalBuilding: unitFormFields.totalBuilding,
        rent: unitFormFields.rent,
        rentType: unitFormFields.rentType,
        offices: unitFormFields.offices,
        bathrooms: unitFormFields.bathrooms,
        size: unitFormFields.size,
        buildingDepth: unitFormFields.buildingDepth,
        clearHeight: unitFormFields.clearHeight,
        parkingRation: unitFormFields.parkingRation,
        sprinklers: unitFormFields.sprinklers,
        water: unitFormFields.water,
        electric: unitFormFields.electric,
        waltRollArea: unitFormFields.waltRollArea,
        numberOfSuites: unitFormFields.numberOfSuites,
        lodingDocks: unitFormFields.lodingDocks,
        gradeLevelDoors: unitFormFields.gradeLevelDoors,
        tenantSize: unitFormFields.tenantSize,
        yearBuild: unitFormFields.yearBuild,
        hvac: unitFormFields.hvac,
        hvacValue: unitFormFields.hvacValue,
      });
    }
    ShowSuccess("Unit generated successfully")
    return units;
  }

  const GenerateUnitsHandler = (e) => {
    e.preventDefault();
    setUnitFormFields({
      ...unitFormFields,
      unitList: GenerateUnits(unitFormFields.numberOfUnits)
    })

  }

  useEffect(() => {
    if (createNewPropertyBody.unitCreationFields) {
      setUnitFormFields(createNewPropertyBody.unitCreationFields)
    }
  }, [])

  const NextHandler = (e) => {
    e.preventDefault();
    if (unitFormFields.unitList.length === 0) {
      ShowError("Please crate units first");
    } else {
      dispatch(PropertyAction.ChangePropertyCreateSteps(4));
      dispatch(
        PropertyAction.CreatePropertyStepThreeAction(unitFormFields, unitFormFields.unitList)
      );
    }



    // alert(JSON.stringify(createNewPropertyBody))
  };

  const [selectedUnit, setSelectedUnit] = useState({ "id": 0, "unitName": "", "totalBuilding": "", "rent": "", "rentType": "", "offices": "", "bathrooms": "", "size": "", "buildingDepth": "", "clearHeight": "", "parkingRation": "", "sprinklers": "", "water": "", "electric": "", "waltRollArea": "", "numberOfSuites": "", "lodingDocks": "", "gradeLevelDoors": "", "tenantSize": "", "yearBuild": "", "hvac": false, "hvacValue": "" })


  function ReplaceSpecificUnit(list, newItem) {
    if (!Array.isArray(list)) {
      throw new Error("The first argument must be an array.");
    }
    if (!newItem || !newItem.id) {
      throw new Error("The newItem must be an object with an id property.");
    }

    return list.map((item) => (item.id === newItem.id ? newItem : item));
  }

  const onSaveHandler = (e) => {
    e.preventDefault()
    setUnitFormFields({
      ...unitFormFields,
      unitList: ReplaceSpecificUnit(unitFormFields.unitList, selectedUnit)
    })
    ShowSuccess("Unit item updated Successfully")
    setIsEdit(false)


  }

  return (
    <div className="w-full h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Let’s Add The Units For The Property"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <div onClick={() => {
          setUnitFormFields({
            ...unitFormFields,
            unitList: createNewPropertyBody.unitData
          })
          console.log(JSON.stringify(createNewPropertyBody, "createNewPropertyBody"))
        }}>
          <ParagraphTag
            content="Add your units"
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
      </div>
      <div className="h-[80%] w-full">
        <div className="flex w-[95%] mx-auto justify-between h-full pt-2">
          <div className="w-[49%] overflow-y-scroll h-full panelScroll px-1 pb-2">
            <form className="w-full">
              <div className="flex justify-between w-full">
                <div className="w-[49%]">
                  <LabelTag
                    isStaric={false}
                    name="No. of Units"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.numberOfUnits}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        numberOfUnits: e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[49%]">
                  <LabelTag
                    isStaric={false}
                    name="Total buildings in the park"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.totalBuilding}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        totalBuilding: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="w-[49%]">
                  <LabelTag
                    isStaric={false}
                    name="Rent"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.rent}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        rent: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[49%]">
                  <LabelTag
                    isStaric={false}
                    name="Rent Type"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <select
                    value={unitFormFields.rentType}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        rentType: e.target.value
                      })
                    }}
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50">
                    <option>Yearly</option>
                    <option>Monthly</option>
                    <option>Quaterly</option>
                  </select>
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Offices (%)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.offices}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        offices: e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Bathrooms"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.bathrooms}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        bathrooms: e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Size (sqft)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.size}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        size: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Building depth (%)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.buildingDepth}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        buildingDepth: e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Clear height (%)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.clearHeight}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        clearHeight: e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Parking Ratio"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.parkingRation}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        parkingRation: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="w-[32%]">
                  <LabelTag

                    isStaric={false}
                    name="Sprinklers"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.sprinklers}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        sprinklers: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Water (Acres)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.water}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        water: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Electric (%)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.electric}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        electric: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="WALT roll area"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.waltRollArea}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        waltRollArea: e.target.value
                      })
                    }}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="# of Suites (%)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.numberOfSuites}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        numberOfSuites: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Loading docks #"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.lodingDocks}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        lodingDocks: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Grade level doors"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.gradeLevelDoors}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        gradeLevelDoors: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Tenant size (Acres)"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.tenantSize}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        tenantSize: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
                <div className="w-[32%]">
                  <LabelTag
                    isStaric={false}
                    name="Year built"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <input
                    value={unitFormFields.yearBuild}
                    onChange={(e) => {
                      setUnitFormFields({
                        ...unitFormFields,
                        yearBuild: e.target.value
                      })
                    }}
                    type="text"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              </div>
              <div className="mt-2 flex justify-between">
                <div className="w-[49%] flex items-center">
                  <LabelTag
                    isStaric={unitFormFields.hvac}
                    name="Does the unit has HVAC?"
                    classes="!text-xs !font-popinsMedium"
                  />
                  <label class="inline-flex items-center cursor-pointer relative pl-3">
                    <input
                      type="checkbox"
                      value={unitFormFields.hvac}
                      class="sr-only peer"
                      onChange={handleCheckboxChange}
                    />
                    <div class="relative w-11 h-6 bg-[#E43B3B38] peer-focus:outline-none rounded-full peer dark:bg-[#E43B3B38] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#E43B3B] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#E43B3B] after:border-[#E43B3B] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#E43B3B] peer-checked:bg-[#E43B3B38]"></div>
                  </label>
                </div>
                {unitFormFields.hvac && (
                  <div className="w-[49%]">
                    <LabelTag
                      isStaric={false}
                      name="HVAC "
                      classes="!text-xs !font-popinsMedium"
                    />
                    <input
                      value={unitFormFields.hvacValue}
                      onChange={(e) => {
                        setUnitFormFields({
                          ...unitFormFields,
                          hvacValue: e.target.value
                        })
                      }}
                      type="number"
                      placeholder="Type here"
                      className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="w-[2%] flex justify-center">
            <div className="h-full w-[1px] bg-black/20"></div>
          </div>
          <div className="w-[49%] overflow-y-scroll h-full panelScroll">
            {unitFormFields?.unitList?.map((item, i) => (
              <UnitsCard data={item} classes="w-full my-2 py-3 px-2 cursor-pointer" onTrigger={() => {
                setSelectedUnit(item)
                openPopup()
              }} />
            ))}
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%] flex justify-center items-center">
          <button onClick={GenerateUnitsHandler} className="font-popinsMedium py-2 flex justify-center items-center w-[30%] text-sm border border-themeColor text-themeColor rounded-md">
            <Plus color="#FF814E" size={18} />
            <span className="pl-2">Generate units</span>
          </button>
        </div>
        <div className="w-[50%] mx-auto flex justify-center items-center">
          <ButtonTag
            onSubmit={NextHandler}
            name="Next"
            classes="text-base text-center bg-themeColor text-white !w-[30%]"
          />
        </div>
      </div>
      {
        isEdit &&
        <EditUnits
          selectedUnit={selectedUnit}
          onSave={onSaveHandler}
          onBack={closePopup}
          unitNameValue={selectedUnit.unitName}
          buildingValue={selectedUnit?.totalBuilding}
          rentValue={selectedUnit?.rent}
          rentTypeValue={selectedUnit.rentType}
          officesValue={selectedUnit.offices}
          bathroomsValue={selectedUnit.bathrooms}
          sizeValue={selectedUnit.size}
          buildingDepthValue={selectedUnit.buildingDepth}
          clearHeightValue={selectedUnit.clearHeight}
          parkingRationValue={selectedUnit.parkingRation}
          sprinklersValue={selectedUnit.sprinklers}
          waterValue={selectedUnit.water}
          electricValue={selectedUnit.electric}
          waltRollAreaValue={selectedUnit.waltRollArea}
          noOfSuitsValue={selectedUnit.numberOfSuites}
          loadingDocksValue={selectedUnit.lodingDocks}
          gradeLevelDoorsValue={selectedUnit.gradeLevelDoors}
          yearBuildValue={selectedUnit.yearBuild}
          tenantsSizeValue={selectedUnit.tenantSize}
          hvacValue={selectedUnit.hvac}
          hvacNumberValue={selectedUnit.hvacValue}

          unitNameValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              unitName: e.target.value
            })
          }}

          buildingValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              totalBuilding: e.target.value
            })
          }}

          rentValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              rent: e.target.value
            })
          }}

          renttypeValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              rentType: e.target.value
            })
          }}

          officesValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              offices: e.target.value
            })
          }}

          bathroomsValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              bathrooms: e.target.value
            })
          }}

          sizeValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              size: e.target.value
            })
          }}

          buildingDepthChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              buildingDepth: e.target.value
            })
          }}


          clearHeighValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              clearHeight: e.target.value
            })
          }}

          parkingRationValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              parkingRation: e.target.value
            })
          }}

          sprinklersValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              sprinklers: e.target.value
            })
          }}


          waterValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              water: e.target.value
            })
          }}

          electricValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              electric: e.target.value
            })
          }}

          waltRollAreaValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              waltRollArea: e.target.value
            })
          }}

          noOfSuitsValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              numberOfSuites: e.target.value
            })
          }}

          loadingDocksValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              lodingDocks: e.target.value
            })
          }}

          gradeLevelDoorsValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              gradeLevelDoors: e.target.value
            })
          }}

          tenantsSizeValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              tenantSize: e.target.value
            })
          }}

          yearBuildValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              tenantSize: e.target.value
            })
          }}

          hvacValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              hvac: !selectedUnit.hvac
            })
          }}

          hvacNumberValueChange={(e) => {
            setSelectedUnit({
              ...selectedUnit,
              hvacValue: e.target.value
            })
          }}
        />
      }

    </div>

  );
};

export default PropertyUnits;
