import React, {useState} from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import { Plus, History, SquareCheck, ArrowUpDown, ArrowDownUp, CircleHelp, SquareX } from "lucide-react";
import db from "../../../../db/index.json";

const LeaseSection = () => {
  const { lease } = db;
  const [data, setData] = useState([
    {
      id: 1,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "active",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 2,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "inactive",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    },
    {
      id: 3,
      title: "1949 Linda Street",
      ID: "PID-000199581",
      unit: "Unit# 7",
      status: "notapprove",
      date: "8/28/2024 - 8/29/2025",
      expire: "Expires in 20 days",
      rent: "$699.87",
      deposite: "$327.20",
    }   
  ]);
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };
  return (
    <div className="bg-white rounded-lg mt-5">
      <div className="w-[95%] mx-auto py-6">
        <div className="flex">
          <div className="w-[65%]">
            <ParagraphTag
              content="Leases"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
          </div>
          <div className="w-[35%] flex justify-between">
            <button className="w-[48%] border border-themeColor rounded-md flex items-center justify-center py-2">
              <History color="#FF814E" size={15} />
              <ParagraphTag
                content="Past Lease"
                classes="text-themeColor font-popinsSemiBold text-xs pl-2"
              />
            </button>
            <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2">
              <Plus color="#FFFFFF" size={15} />
              <ParagraphTag
                content="New Lease"
                classes="text-white font-popinsSemiBold text-xs pl-2"
              />
            </button>
          </div>
        </div>
        <div className="w-full mt-5">
          <table className="w-full ">
            <thead className="sticky top-0 bg-white">
              <tr className="border-b border-black/20">
                <th className="w-[20%] text-left pb-2">
                  <span className="flex">
                    <ParagraphTag
                      content={lease.heading}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "lease" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("property")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.units}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "units" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("units")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("units")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("units")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.status}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "status" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("status")}
                      />
                    )}
                  </span>
                </th>

                <th className="w-[20%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.duration}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                    />
                    {sortConfig.key === "duration" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("duration")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("duration")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("duration")}
                      />
                    )}
                  </span>
                </th>

                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.rent}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "rent" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("rent")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("rent")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("rent")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[8%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={lease.deposit}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "deposit" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("deposit")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("deposit")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("deposit")}
                      />
                    )}
                  </span>
                </th>

                <th className="w-[28%] text-right pr-4 pb-2">
                  <ParagraphTag
                    content={lease.actions}
                    classes="text-darkColor/40 font-popinsSemiBold text-xs"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 rounded-md cursor-pointer"
                >
                  <td className="py-2">
                    <div className="flex items-center justify-between">
                      <div className="w-[18%] rounded-full">
                        <ImageTag
                          path="/assets/daashboard/balance.png"
                          classes="w-full"
                          altText="logo"
                        />
                      </div>

                      <div
                        className="w-[78%] cursor-pointer"
                      >
                        <ParagraphTag
                          content={item.ID}
                          classes="text-darkColor/50 font-popinsMedium text-xs"
                        />
                        <ParagraphTag
                          content={item.title}
                          classes="text-darkColor font-popinsSemiBold text-sm"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {item.unit}
                  </td>
                  <td className="py-2 font-popinsMedium text-darkColor text-sm">
                    <span className="flex justify-center">
                      {(() => {
                        switch (item.status) {
                          case "active":
                            return <SquareCheck color="#01CB78" size={20} />;
                          case "notapprove":
                            return <CircleHelp color="#B3B3B3" size={20} />;
                          default:
                            return <SquareX color="#E43B3B" size={20} />;
                        }
                      })()}
                    </span>
                  </td>

                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-xs text-center">
                    <div className="flex flex-col">
                      <span>{item.date}</span>
                      <span className="text-darkColor/50">{item.expire}</span>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {item.rent}
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {item.deposite}
                  </td>
                  <td className="py-2  px-4 font-popinsMedium text-darkColor text-sm">
                    <div className="flex justify-end">
                      <button className="flex items-center border border-[#6F6F6F] text-[#6F6F6F] font-popinsSemiBold px-2 py-1 rounded text-xs mr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 pr-2"
                          viewBox="0 0 15 19"
                          fill="none"
                        >
                          <path
                            d="M10.5938 2.5H12C13.2305 2.5 14.25 3.51953 14.25 4.75V16C14.25 17.2656 13.2305 18.25 12 18.25H3C1.73438 18.25 0.75 17.2656 0.75 16V4.75C0.75 3.51953 1.73438 2.5 3 2.5H4.40625H4.72266C5.00391 1.23438 6.12891 0.25 7.5 0.25C8.83594 0.25 9.99609 1.23438 10.2422 2.5H10.5938ZM3 4.1875C2.68359 4.1875 2.4375 4.46875 2.4375 4.75V16C2.4375 16.3164 2.68359 16.5625 3 16.5625H12C12.2812 16.5625 12.5625 16.3164 12.5625 16V4.75C12.5625 4.46875 12.2812 4.1875 12 4.1875H11.4375V5.03125C11.4375 5.52344 11.0508 5.875 10.5938 5.875H7.5H4.40625C3.91406 5.875 3.5625 5.52344 3.5625 5.03125V4.1875H3ZM7.5 3.90625C7.95703 3.90625 8.34375 3.55469 8.34375 3.0625C8.34375 2.60547 7.95703 2.21875 7.5 2.21875C7.00781 2.21875 6.65625 2.60547 6.65625 3.0625C6.65625 3.55469 7.00781 3.90625 7.5 3.90625Z"
                            fill="#6F6F6F"
                          />
                        </svg>
                        Renew Lease
                      </button>
                      <button className="flex items-center border border-themeColor text-themeColor font-popinsSemiBold px-2 py-1 rounded text-xs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 pr-2"
                          viewBox="0 0 15 19"
                          fill="none"
                        >
                          <path
                            d="M10.5938 2.5H12C13.2305 2.5 14.25 3.51953 14.25 4.75V16C14.25 17.2656 13.2305 18.25 12 18.25H3C1.73438 18.25 0.75 17.2656 0.75 16V4.75C0.75 3.51953 1.73438 2.5 3 2.5H4.40625H4.72266C5.00391 1.23438 6.12891 0.25 7.5 0.25C8.83594 0.25 9.99609 1.23438 10.2422 2.5H10.5938ZM3 4.1875C2.68359 4.1875 2.4375 4.46875 2.4375 4.75V16C2.4375 16.3164 2.68359 16.5625 3 16.5625H12C12.2812 16.5625 12.5625 16.3164 12.5625 16V4.75C12.5625 4.46875 12.2812 4.1875 12 4.1875H11.4375V5.03125C11.4375 5.52344 11.0508 5.875 10.5938 5.875H7.5H4.40625C3.91406 5.875 3.5625 5.52344 3.5625 5.03125V4.1875H3ZM7.5 3.90625C7.95703 3.90625 8.34375 3.55469 8.34375 3.0625C8.34375 2.60547 7.95703 2.21875 7.5 2.21875C7.00781 2.21875 6.65625 2.60547 6.65625 3.0625C6.65625 3.55469 7.00781 3.90625 7.5 3.90625Z"
                            fill="#FF814E"
                          />
                        </svg>
                        Renew Lease
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeaseSection;
