import React, { useState } from "react";
import ParagraphTag from "../reusebale/Paragraph";
import LabelTag from "../reusebale/label";
import ButtonTag from "../reusebale/button";

const EditUnits = ({
  selectedUnit,
  onSave,
  onBack,
  unitNameValue,
  unitNameValueChange,
  buildingValue,
  buildingValueChange,
  rentValue,
  rentValueChange,
  rentTypeValue,
  renttypeValueChange,
  officesValue,
  officesValueChange,
  bathroomsValue,
  bathroomsValueChange,
  sizeValue,
  sizeValueChange,
  buildingDepthValue,
  buildingDepthChange,
  clearHeightValue,
  clearHeighValueChange,
  parkingRationValue,
  parkingRationValueChange,
  sprinklersValue,
  sprinklersValueChange,
  waterValue,
  waterValueChange,
  electricValue,
  electricValueChange,
  waltRollAreaValue,
  waltRollAreaValueChange,
  noOfSuitsValue,
  noOfSuitsValueChange,
  loadingDocksValue,
  loadingDocksValueChange,
  gradeLevelDoorsValue,
  gradeLevelDoorsValueChange,
  tenantsSizeValue,
  tenantsSizeValueChange,
  yearBuildValue,
  yearBuildValueChange,
  hvacValue,
  hvacValueChange,
  hvacNumberValue,
  hvacNumberValueChange,



}) => {
  const [isHVAC, setIsHVAC] = useState(false);
  const handleCheckboxChange = () => {
    setIsHVAC((prevState) => !prevState);
  };
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[50%] xl:w-[50%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
          <div>
            <ParagraphTag
              content={`Edit ${selectedUnit?.unitName}`}
              classes={`text-darkColor font-popinsSemiBold text-sm `}
            />
          </div>
          <form className="w-[95%] mx-auto h-[65vh] overflow-y-scroll panelScroll px-1 pt-3 pb-5">
            <div className="flex justify-between w-full">

              <div className="w-[49%]">
                <LabelTag
                  isStaric={false}
                  name="Unit Name"
                  classes="!text-xs !font-popinsMedium"
                />

                <input
                  value={unitNameValue}
                  onChange={unitNameValueChange}
                  type="text"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>

              <div className="w-[49%]">
                <LabelTag
                  isStaric={false}
                  name="Total buildings in the park"
                  classes="!text-xs !font-popinsMedium"
                />

                <input
                  value={buildingValue}
                  onChange={buildingValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="w-[49%]">
                <LabelTag
                  isStaric={false}
                  name="Rent"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  onChange={rentValueChange}
                  value={rentValue}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[49%]">
                <LabelTag
                  isStaric={false}
                  name="Rent Type"
                  classes="!text-xs !font-popinsMedium"
                />
                <select value={rentTypeValue} onChange={renttypeValueChange} className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50">
                  <option>Yearly</option>
                  <option>Monthly</option>
                  <option>Quaterly</option>
                </select>
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Offices (%)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  onChange={officesValueChange}
                  value={officesValue}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Bathrooms"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  onChange={bathroomsValueChange}
                  value={bathroomsValue}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Size"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  onChange={sizeValueChange}
                  value={sizeValue}
                  type="text"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Building depth (%)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={buildingDepthValue}
                  onChange={buildingDepthChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Clear height (%)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={clearHeightValue}
                  onChange={clearHeighValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Parking Ratio"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={parkingRationValue}
                  onChange={parkingRationValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Sprinklers"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={sprinklersValue}
                  onChange={sprinklersValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Water (Acres)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={waterValue}
                  onChange={waterValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Electric (%)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={electricValue}
                  onChange={electricValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="WALT roll area"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={waltRollAreaValue}
                  onChange={waltRollAreaValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="# of Suites (%)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={noOfSuitsValue}
                  onChange={noOfSuitsValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Loading docks #"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={loadingDocksValue}
                  onChange={loadingDocksValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Grade level doors"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={gradeLevelDoorsValue}
                  onChange={gradeLevelDoorsValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Tenant size (Acres)"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={tenantsSizeValue}
                  onChange={tenantsSizeValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
              <div className="w-[32%]">
                <LabelTag
                  isStaric={false}
                  name="Year built"
                  classes="!text-xs !font-popinsMedium"
                />
                <input
                  value={yearBuildValue}
                  onChange={yearBuildValueChange}
                  type="number"
                  placeholder="Type here"
                  className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                />
              </div>
            </div>
            <div className="mt-2 flex justify-between">
              <div className="w-[49%] flex items-center">
                <LabelTag
                  isStaric={false}
                  name="Does the unit has HVAC?"
                  classes="!text-xs !font-popinsMedium"
                />
                <label class="inline-flex items-center cursor-pointer relative pl-3">
                  <input
                    type="checkbox"
                    value={true}
                    checked={hvacValue}
                    class="sr-only peer"
                    onChange={hvacValueChange}
                  />
                  <div class="relative w-11 h-6 bg-[#E43B3B38] peer-focus:outline-none rounded-full peer dark:bg-[#E43B3B38] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#E43B3B] after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#E43B3B] after:border-[#E43B3B] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#E43B3B] peer-checked:bg-[#E43B3B38]"></div>
                </label>
              </div>
              {hvacValue && (
                <div className="w-[49%]">
                  {/* <LabelTag
                      isStaric={false}
                      name="HVAC"
                      classes="!text-xs !font-popinsMedium"
                    /> */}
                  <input
                    value={hvacNumberValue}
                    onChange={hvacNumberValueChange}
                    type="number"
                    placeholder="Type here"
                    className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
                  />
                </div>
              )}
            </div>

          </form>
          <div className="w-[50%] mx-auto flex justify-center items-center">
            {
              onSave && <ButtonTag
                onSubmit={onSave}
                name="Save"
                classes="text-sm text-center bg-themeColor text-white !w-[60%]"
              />
            }

          </div>
          <div className="w-[50%] mx-auto flex justify-center items-center">
            <ButtonTag
              onSubmit={onBack}
              name="Close"
              classes="text-sm text-center text-darkColor !w-[30%]"
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditUnits;
