import React from "react";
import HomeSection from '../home/home';
import LeasesSection from '../leases/leases';
import MaintainanceSection from '../maintenance/maintainance';
import PropertySection from '../properties/properties';
import UnitsSection from '../units/units';
import TenantsSection from '../tenants/tenants';
import ReportsSection from '../reports/reports';
import HelpSection from '../help-tutorial/help';
import LogoutSection from '../logout';
import BroadCast from '../broadcast/broadcastMain'
import Notification from '../notification/main';
import ViewProfile from '../view-profile/profile'

const RightSection = ({ activeComponent, setActiveComponent }) => {

  // Function to show the Notification component
  const handleShowNotification = () => {
    setActiveComponent("Notifications");  // Set activeComponent to "Notification"
  };

  // Function to show the Home component
  const handleShowHome = () => {
    setActiveComponent("Home");  // Set activeComponent to "Home"
  };

  // If the activeComponent is "Notification", show the Notification component
  if (activeComponent === "Notifications") {
    return <Notification onBack={handleShowHome} />;
  }

  // Show the HomeSection component if activeComponent is "Home"
  if (activeComponent === "Home") {
    return <HomeSection onNotificationClick={handleShowNotification} />;
  }

  // Handle other sections
  if (activeComponent === "Tenants") return <TenantsSection />;
  if (activeComponent === "Properties") return <PropertySection />;
  if (activeComponent === "Units") return <UnitsSection />;
  if (activeComponent === "Maintenance") return <MaintainanceSection />;
  if (activeComponent === "Leases") return <LeasesSection />;
  if (activeComponent === "Reports") return <ReportsSection />;
  if (activeComponent === "Broadcasts") return <BroadCast />;
  if (activeComponent === "Help") return <HelpSection />;
  if (activeComponent === "Logout") return <LogoutSection />;
  if (activeComponent === "Profile") return <ViewProfile />;

  return null; // Default case (if no activeComponent matches)
};

export default RightSection;
