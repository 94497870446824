import React, { useState, useEffect } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import db from "../../../../db/index.json";
import { useSelector, useDispatch } from "react-redux";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import BaseUrl from "../../../../constants/BaseUrl";
import * as AuthAction from "../../../../store/actions/auth/AuthAction";
import MyLoader from "../../../reusebale/myLoader";

const CompanyForm = ({ onBack }) => {
  const { completeProfileData } = db;
  const { loginSuccess, userCompleteProfile } = useSelector(
    (state) => state.auth
  );

  //All cities and there state from globle state
  const { allCitiesAndStates } = useSelector((state) => state.data);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // Log the data when component mounts or `allCitiesAndStates` changes
    console.log("Cities and States Data:", allCitiesAndStates);
  }, [allCitiesAndStates]);

  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    companyName: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.companyName
      : "",
    companyWebsite: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.companyWebsite
      : "",
    street1: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.street1
      : "",
    street2: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.street2
      : "",
    city: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.city
      : "",
    state: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.state
      : "",
    country: "United State",
    zipCode: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.zipCode
      : "",
    completeAddress: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.completeAddress
      : "",
    longitude: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.longitude
      : "",
    latitude: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.latitude
      : "",
    companyLogo: userCompleteProfile?.companyInformation
      ? userCompleteProfile?.companyInformation?.companyLogo
      : "",
  });

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setFormFields({
      ...formFields,
      state: e.target.value,
    });

    // Find the selected state's cities and set in the state
    const stateData = allCitiesAndStates.find((item) => item.state === state);
    setCities(stateData ? stateData.cities : []); // Update cities
  };

  const UpdateCompanyProfile = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      landloardId: loginSuccess?.user?._id,
      companyInformation: {
        companyName: formFields.companyName,
        companyWebsite: formFields.companyWebsite,
        street1: formFields.street1,
        street2: formFields.street2,
        city: formFields.city,
        state: formFields.state,
        country: formFields.country,
        zipCode: formFields.zipCode,
        completeAddress: formFields.completeAddress,
        longitude: "990.99",
        latitude: "3390.99",
        companyLogo:
          "https://marketplace.canva.com/EAF7NE1jgCo/1/0/1600w/canva-white-and-blue-flat-illustrative-real-estate-agency-logo-V6qr80Pdw2Y.jpg",
      },
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}landlord/profile/createNewProfile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          setLoading(false);
          ShowSuccess("Company Information updated successfully");
          dispatch(AuthAction.GetLandlordProfile(loginSuccess?.user?._id));
          onBack();
        } else {
          setLoading(false);
          ShowSuccess(result.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const UpdateHandler = (e) => {
    e.preventDefault();
    if (formFields.companyName === "") {
      ShowError("Company name is required");
    } else if (formFields.completeAddress === "") {
      ShowError("Company address is required");
    } else if (formFields.street1 === "") {
      ShowError("Street 1 is required");
    } else if (formFields.street2 === "") {
      ShowError("Street 2 is required");
    } else if (formFields.city === "") {
      ShowError("City is required");
    } else if (formFields.state === "") {
      ShowError("State is required");
    } else if (formFields.zipCode === "") {
      ShowError("Zip code is required");
    } else {
      UpdateCompanyProfile();
    }
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="w-[95%] mx-auto py-5">
        <ParagraphTag
          content={completeProfileData.companyProfile.title}
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
        <form>
          <div className="flex justify-between mt-5">
            <div className="w-[49%]">
              <LabelTag
                name={completeProfileData.companyProfile.label1}
                className="text-black"
              />
              <input
                value={formFields.companyName}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    companyName: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter your company name.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="w-[49%]">
              <LabelTag
                name={completeProfileData.companyProfile.label2}
                className="text-black"
              />
              <input
                value={formFields.companyWebsite}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    companyWebsite: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter your website name.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>
          <div class="w-full mt-3">
            <LabelTag
              name={completeProfileData.companyProfile.label3}
              className="text-black"
            />
            <input
              value={formFields.completeAddress}
              onChange={(e) => {
                setFormFields({
                  ...formFields,
                  completeAddress: e.target.value,
                });
              }}
              type="text"
              placeholder="Enter your complete address.."
              className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="flex justify-between">
            <div className="w-[49%] mt-3">
              <LabelTag
                name={completeProfileData.companyProfile.label4}
                className="text-black"
              />
              <input
                value={formFields.street1}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    street1: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter your street 1.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="w-[49%] mt-3">
              <LabelTag
                name={completeProfileData.companyProfile.label5}
                className="text-black"
              />
              <input
                value={formFields.street2}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    street2: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter your street 2.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[49%] mt-3">
              <LabelTag
                name={completeProfileData.companyProfile.label7}
                className="text-black"
              />
              <select
                value={selectedState}
                onChange={handleStateChange}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm mb-4"
              >
                <option value="" disabled>
                  -- Select a State --
                </option>
                {allCitiesAndStates.map((item, index) => (
                  <option key={index} value={item.state}>
                    {item.state}
                  </option>
                ))}
              </select>
              {/* <div className="relative group">
                <input
                  value={formFields.state}
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      state: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="Enter your state.."
                  className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50 cursor-pointer"
                />
                <div className="bg-red-500 shadow-xl py-3 hidden group-hover:flex group-hover:flex-col border border-white/20 rounded-xl w-full right-0 top-[42px] absolute h-[150px] overflow-scroll panelScroll">
                  {allCitiesAndStates.map((item, i) => (
                    <p 
                      key={i}
                      onClick={() => handleSelect(item.state)}
                      className="text-darkColor font-popinsRegular cursor-pointer text-sm pt-4 pl-4 border-b-[1px] border-darkColor/20 pb-2">
                      {item.state}
                    </p>
                  ))}
                </div>
              </div> */}
            </div>
            <div className="w-[49%] mt-3">
              <LabelTag
                name={completeProfileData.companyProfile.label6}
                className="text-black"
              />
              <select
                value={formFields.city}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    city: e.target.value,
                  });
                }}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm"
                disabled={!selectedState || cities.length === 0} // Disable if no state selected
              >
                <option value="" disabled>
                  {cities.length === 0
                    ? "Select a state first"
                    : "-- Select a City --"}
                </option>
                {cities.map((city) => (
                  <option key={city.id} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[49%] mt-3">
              <LabelTag
                name={completeProfileData.companyProfile.label8}
                className="text-black"
              />
              <input
                disabled
                value={formFields.country}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    country: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter your country.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="w-[49%] mt-3">
              <LabelTag
                name={completeProfileData.companyProfile.label9}
                className="text-black"
              />
              <input
                value={formFields.zipCode}
                onChange={(e) => {
                  setFormFields({
                    ...formFields,
                    zipCode: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter your zip code.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>

          <div className="w-full bg-themeColor text-white mt-5 rounded-md">
            {loading ? (
              <div className="flex w-full justify-center items-center py-2">
                <MyLoader />
              </div>
            ) : (
              <ButtonTag onSubmit={UpdateHandler} name={"Update"} />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyForm;
