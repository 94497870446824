import React from "react";

const NewLeases = () => {

  return (
    <div>
        leases
    </div>
  );
};

export default NewLeases;
