import React, { useState } from "react";
import db from "../../../../db/index.json";
import ParagraphTag from "../../../reusebale/Paragraph";
import IconCards from "../../../reusebale/iconCards";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";

const SuitableDate = () => {
  const [Funds, setFunds] = useState(db.maintainances.permissionEnter);

  const handleCardClick = (name) => {
    const updatedTypes = Funds.map((property) =>
      property.title === name
        ? { ...property, active: true }
        : { ...property, active: false }
    );

    setFunds(updatedTypes);
  };

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Set a Suitable Date"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <ParagraphTag
          content="lorem ipsum"
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className=" w-[70%] mx-auto">
          <div className="flex justify-between mt-3">
            <div className="w-[49%]">
              <LabelTag isStaric={false} name="Date" />
              <select
                value=""
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                <option value="Select1">Select 1</option>
                <option value="Select2">Select 2</option>
                <option value="Select3">Select 3</option>
              </select>
            </div>
            <div className="w-[49%]">
              <LabelTag isStaric={false} name="Due Date" />
              <select
                value=""
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                <option value="Select1">Select 1</option>
                <option value="Select2">Select 2</option>
                <option value="Select3">Select 3</option>
              </select>
            </div>
          </div>
          <div className="text-center py-3">
            <ParagraphTag
              content="Set Priority"
              classes="text-darkColor font-popinsSemiBold text-base"
            />
            <div>
              <ParagraphTag
                content="lorem ipsum"
                classes="text-[#686868] font-popinsRegular text-xs"
              />
            </div>
          </div>
          <div className="flex justify-center py-4">
            <div className="bg-[#E43B3B1A] rounded-lg px-6 py-2 hover:bg-themeColor hover:text-white cursor-pointer">
              <p className="text-xs font-popinsMedium">Low Priority</p>
            </div>
            <div className="bg-[#E43B3B1A] rounded-lg px-6 py-2 mx-3 hover:bg-themeColor hover:text-white cursor-pointer">
              <p className="text-xs font-popinsMedium">Regular priority</p>
            </div>
            <div className="bg-[#E43B3B1A] rounded-lg px-6 py-2 hover:bg-themeColor hover:text-white cursor-pointer">
              <p className="text-xs font-popinsMedium">High priority</p>
            </div>
          </div>
          <div className="text-center py-3">
            <ParagraphTag
              content="Permission To Enter"
              classes="text-darkColor font-popinsSemiBold text-base"
            />
            <ParagraphTag
              content="Lorem ipsum"
              classes="text-[#686868] font-popinsRegular text-xs"
            />
          </div>
          <div className="flex xl:w-[60%] 2xl:w-[50%] mx-auto justify-between mt-2">
            {Funds.map((item) => (
              <div
                key={item.title}
                className="w-[50%] cursor-pointer flex flex-col items-center"
                onClick={() => handleCardClick(item.title)}
              >
                <IconCards
                  mainClasses={`h-36 w-[130px] ${
                    item.active ? "border-red-500 border-2" : ""
                  }`}
                  imgClasses="w-[35px]"
                  path={item.active ? item.imagePathActiv : item.imagePath}
                  title={item.title}
                  titleClasses={
                    item.active ? "text-themeColor" : "text-darkColor"
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto mt-4 mb-2">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default SuitableDate;
