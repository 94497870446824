import React from "react";
import LabelTag from "../reusebale/label";
import { Link } from 'react-router-dom';
import { Eye } from "lucide-react";
import ButtonTag from "../reusebale/button";

const ResetFormSection = () => {


    return (
        <form className="mt-8">
            <div className="w-full">
                <LabelTag name='New Password' />
                <div className="relative">
                    <input

                        type="password"
                        placeholder="Create password here.."
                        className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
                    />
                    <div
                        title="Show password"
                        className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
                    >
                        <Eye size={20} />
                    </div>
                </div>
            </div>
            <div className="w-full pt-4 lg:mt-0">
                <LabelTag name='Confirm New Password' />
                <div className="relative">
                    <input
                        placeholder="Confirm password here.."
                        className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
                    />
                    <div
                        title="Show password"
                        className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
                    >
                        <Eye size={20} />
                    </div>
                </div>
            </div>
            <Link to={"/login"}>
                <ButtonTag name='Submit' classes=' mt-5 text-base text-center text-white bg-themeColor text-themeColor mb-8 lg:mb-0' />
            </Link>
        </form>
    );
};

export default ResetFormSection;
