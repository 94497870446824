import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import ProgressPopup from "../../../popup/progressPopup";
import db from "../../../../db/index.json";
import {
  CarouselProvider,
  Slider,
  Slide
} from "pure-react-carousel";

const OverviewRequest = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { maintainances } = db;

  const openPopup = () => {
    setShowSuccess(true);
  };

  const closePopup = () => {
    setShowSuccess(false);
  };

  return (
    <div className="w-full">
      <div className="bg-white rounded-lg w-full pt-5">
        <div className="w-[95%] mx-auto">
          <div>
            <ParagraphTag
              content="Request Overview"
              classes={`text-darkColor font-popinsSemiBold text-lg`}
            />
          </div>
          <div className="flex justify-between mt-4">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Property"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="2323 Dancing Dove Lane, Long Island City, NY 11101"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Unit"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Unit #14"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <ParagraphTag
              content="Description"
              classes={`text-darkColor font-popinsSemiBold text-sm`}
            />
            <div>
              <ParagraphTag
                content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt moles, massa nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac habitasse platea dictumst. Vivamus vehicula leo dui, "
                classes={`text-darkColor/40 font-popinsRegular text-xs`}
              />
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Category"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Lorem Ipsum"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Date"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Oct 26, 2024 12:52 am"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Due Date"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Oct 27, 2024 5:55 am"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Caller Name"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="184 Griffin Street, Gilbert, AZ 85233"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Caller Email"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="r.m.smith@gmail.com"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Caller Phone"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="(830) 556-6651"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[59%]">
              <div>
                <ParagraphTag
                  content="Tech Note"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="NA"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[40%]">
              <div>
                <ParagraphTag
                  content="Access Note"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="NA"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[40%] mx-auto mt-5 pb-5" onClick={openPopup}>
            <ButtonTag
              name="Update Progress"
              classes="font-popinsMedium text-sm text-center bg-themeColor text-white"
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg mt-3 px-4 py-5">
        <div>
          <ParagraphTag
            content="Images"
            classes={`text-darkColor font-popinsSemiBold text-lg mb-3`}
          />
          <CarouselProvider
            naturalSlideWidth={19} // 3 images per slide, each taking 33.33% of the container width
            naturalSlideHeight={20} // Set height of images to 120px
            totalSlides={maintainances.dummyImage.length}
            visibleSlides={5} // Display 3 images at once
            infinite
            step={1} // Slide one by one
            isDragEnabled={maintainances?.dummyImage?.length > 3} // Enable drag functionality
            dragVelocity={2} // Adjust drag speed (optional)
          >
            <div className="relative w-full">
              <Slider>
                {maintainances.dummyImage.map((image, index) => (
                  <Slide key={index} index={index}>
                    <div className="w-[90%] h-[100px] relative overflow-hidden">
                      <img
                        src={image.imagePath}
                        alt={`Slide ${index}`}
                        className="w-full h-full object-cover" // Ensures images cover the full height and width
                      />
                    </div>
                  </Slide>
                ))}
              </Slider>
            </div>
          </CarouselProvider>
        </div>
      </div>
      {showSuccess && (
        <ProgressPopup onFilter={closePopup} onBack={closePopup} />
      )}
    </div>
  );
};

export default OverviewRequest;
