import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";

const InfoSection = () => {
  return (
    <div className="bg-white rounded-xl mt-6">
      <div className="flex mt-1 items-center w-[50%] py-5">
        <div className="w-[30%] flex justify-center">
          <ImageTag
            path="/assets/daashboard/company-info-Round.png"
            classes="w-[135px] rounded-full"
            altText="logo"
          />
        </div>
        <div className="w-[70%]">
          <div>
            <ParagraphTag
              content="Tenant Info"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
          </div>
          <div className="pt-3 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="Name"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content="John Doe"
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Name"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content="John Doe"
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
          <div className="pt-3 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="Email Address"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content="John Doe123@email.com"
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Phone"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content="+4256932997"
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
