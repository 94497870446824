
import { CommonDataActionConst } from "../../constants/DataActionConst"
const initialState = {
    loader: false,
    allData: [{ name: "musharaf" }],
    allCitiesAndStates: [],
    propertyTypes: []

}
export default (state = initialState, action) => {
    switch (action.type) {
        //Cities And States

        case CommonDataActionConst.GET_COMMON_MASTER_DATA_REQ:
            state = {
                ...state,
                loader: true,


            }
            break

        case CommonDataActionConst.GET_COMMON_MASTER_DATA_SUCCESS:
            state = {
                ...state,
                loader: false,
                allCitiesAndStates: action.result.citiesAndStates,
                propertyTypes: action.result.propertyTypes
            }
            break

        case CommonDataActionConst.GET_COMMON_MASTER_DATA_FAIL:
            state = {
                ...state,
                loader: false,

            }
            break


    }
    return state

}