import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import db from '../../db/index.json';
import LabelTag from '../reusebale/label'
import ParagraphTag from '../reusebale/Paragraph'
import ButtonTag from '../reusebale/button'
import { EyeOff, Eye } from 'lucide-react';
import { useSelector, useDispatch } from "react-redux"
import { ShowError } from "../../utils/ToasFunction";
import MyLoader from "../reusebale/myLoader";
import * as AuthAction from "../../store/actions/auth/AuthAction"
const FormSection = () => {
  const { loader, loginSuccess, loginError } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formFields, setFormFields] = useState({
    email: "",
    password: ""
  })
  const { login } = db;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const SaveDataToLocal = (data) => {
    localStorage.setItem('loginSuccess', JSON.stringify(data));
  }
  useEffect(() => {
    if (loginSuccess) {
      SaveDataToLocal(loginSuccess)
      setFormFields({
        ...formFields,
        email: "",
        password: "",
      })
      navigate("/dashboard")
    } else if (loginError?.data?.email) {
      navigate("/account-verification")
    } else {
      ShowError(loginError?.message)
    }

  }, [loginSuccess, loginError])


  const loginHandler = (e) => {
    e.preventDefault();
    if (formFields.email === "") {
      ShowError("Please enter your email address first")
    } else if (formFields.password === "") {
      ShowError("Please enter your password first")
    } else {
      dispatch(AuthAction.UserLogInAction(formFields))
    }
  }

  return (
    <form>

      <div class="w-full mt-3">
        <LabelTag name={login.emailLabel} classes="!text-xs"/>
        <input
          value={formFields.email}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              email: e.target.value
            })
          }}
          type="text"
          placeholder="Enter your email.."
          className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
        />
      </div>
      <div className="w-full mt-3">
        <LabelTag name={login.passLabel} classes="!text-xs"/>
        <div className="relative">
          <input
            value={formFields.password}
            onChange={(e) => {
              setFormFields({
                ...formFields,
                password: e.target.value
              })
            }}
            type={showPassword ? "text" : "password"}
            placeholder="Password here.."
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
          />
          <div
            title="Show password"
            className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-3 ">
        <div className="flex w-[50%]">
          <input

            type="checkbox"
            className="w-4 bg-bgColor rounded-sm cursor-pointer"
          />
          <ParagraphTag content={login.remember} classes='text-[#686868] text-xs pl-3 font-popinsRegular' />
        </div>
        <Link to={'/forget-password'} className="w-[50%] flex justify-end">
          <ParagraphTag content={login.forgetPass} classes='text-themeColor text-xs font-popinsMedium cursor-pointer' />
        </Link>
      </div>
      {
        loader ? <div className="flex justify-center items-center">
          <MyLoader />
        </div> : <ButtonTag onSubmit={loginHandler} name={login.SubmitBtn} classes='text-base bg-themeColor hover:bg-themeColor/90 mt-3 text-center  text-white' />
      }

      <div className="flex items-center my-2">
        <div className="w-[15%] lg:w-[30%] 2xl:w-[35%] bg-[#1A1A1A]/30 h-[1px]"></div>
        <div className="w-[70%] lg:w-[40%] 2xl:w-[30%] flex justify-center">
          <ParagraphTag content={login.account} classes='text-[#686868] font-popinsRegular text-xs' />
        </div>
        <div className="w-[15%] lg:w-[30%] 2xl:w-[35%] bg-[#1A1A1A]/30 h-[1px]"></div>
      </div>
      <Link to={"/signup"}>
        <ButtonTag name={login.signUpBtn} classes='text-base text-center border-2 border-themeColor text-themeColor mb-8 lg:mb-0' />
      </Link>

    </form>
  );
};

export default FormSection;
