import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import db from "../../../db/index.json";
import TenantsTable from "../tenants/tenantsTable";
import MainSection from "../tenants/tenantsDetail/main";
import FilterPopup from "../../popup/filterPopup"

const TenantsSection = () => {
  const { tenants } = db;

  const [showTable, setShowTable] = useState('table'); // Default: Table show ho raha hai

  const [showFilter, setShowFilter] = useState(false);

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = () => {
    setShowTable("detail"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div>
                <SearchBar title={tenants.title} desc={tenants.desc} onSubmit={openPopup}/>
                <TenantsTable onViewDetails={toggleDetail} />
              </div>
            );
          case "detail":
            return <MainSection onBack={toggleTable}/>;
          default:
             break;
        }
      })()}

      {showFilter && <FilterPopup onBack={closePopup} onFilter={closePopup} />}
    </div>
  );
};

export default TenantsSection;
