import React from "react";
import ParagraphTag from "../reusebale/Paragraph";
import ButtonTag from '../reusebale/button';
import db from "../../db/index.json";
import ImageTag from '../../component/reusebale/imageTag'

const PropertySuccessPopup = ({onClose}) => {
  const { popupData } = db;
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[25%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col">
        <ImageTag path='/assets/daashboard/create-propety/property-succes.png' classes='w-[45%]' altText='login' />
          <ParagraphTag
            content={popupData.success.title}
            classes=" text-darkColor font-popinsBold text-xl pt-2"
          />
          <ParagraphTag
            content={popupData.success.description}
            classes=" text-darkColor font-popinsMedium text-xs pt-2"
          />
          <div className="w-[90%]">
            <ButtonTag name={popupData.success.buttonName} classes=' mt-3 text-base text-center border-2 border-themeColor text-white bg-themeColor' onSubmit={onClose}/>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PropertySuccessPopup;
