import React from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css"; // Ensure CSS is imported

const ImageCarousel = ({ allDetails }) => {
  return (
    <div className="bg-white w-full h-[200px] flex items-center justify-center mt-5 rounded-md">
      {/* Outer flex container ensures vertical and horizontal centering */}
      <div className="w-[95%] h-[150px] overflow-hidden">
        {/* CarouselProvider settings */}
        <CarouselProvider
          naturalSlideWidth={20} // Each image takes 20% of the container width
          naturalSlideHeight={20} // Adjust height proportionally
          totalSlides={allDetails?.images.length} // Total number of images
          visibleSlides={5} // Show 5 images per slide
          infinite={allDetails?.images.length > 5} // Enable infinite scrolling
          step={1} // Slide one by one
          isDragEnabled={allDetails?.images.length > 5} // Enable drag functionality
        >
          <Slider>
            {allDetails?.images.map((image, index) => (
              <Slide key={index} index={index}>
                <div className="w-[90%] h-full overflow-hidden mx-auto">
                  <img
                    src={image.imageUrl}
                    alt={`Slide ${index}`}
                    className="w-full h-full object-cover rounded-md" // Ensures images cover the full height and width
                  />
                </div>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default ImageCarousel;
