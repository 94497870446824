import React from "react";
import ReactApexChart from "react-apexcharts";

class HorizonatlBarChart extends React.Component {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      series: [
        {
          data: [0, 0, 0, 0], // Start with zero for animation
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          animations: {
            enabled: true, // Enable animation
            easing: "easeinout", // Smooth easing
            speed: 3000, // Slow animation speed (3 seconds)
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8, // Rounded bar edges
            horizontal: true, // Horizontal bars
            distributed: true, // Each bar has its own color
            barHeight: "50%", // Bar height
          },
        },
        colors: ["#4C6EF5", "#37D67A", "#FF4D4D", "#FFA726"], // Custom colors for each bar
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#000"], // Black labels
            fontSize: "14px",
          },
          offsetX: 5,
        },
        xaxis: {
          categories: [
            "1949 Linda Street",
            "3522 West Fork",
            "612 Shadowman",
            "1949 Linda Street",
          ], // Labels for bars
          labels: {
            style: {
              colors: "#666",
              fontSize: "14px",
            },
          },
        },
        grid: {
          borderColor: "#e0e0e0", // Light gridlines
        },
        tooltip: {
          enabled: true,
          theme: "light",
        },
        fill: {
          type: "solid", // Solid fill
        },
        stroke: {
          show: false, // Remove bar outlines
        },
      },
    };
  }

  componentDidMount() {
    // Trigger the smooth fill animation by setting final values
    setTimeout(() => {
      this.setState({
        series: [
          {
            data: [10, 8, 4, 4], // Final bar values
          },
        ],
      });
    }, 500); // Slight delay before starting the animation
  }

  render() {
    return (
      <div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default HorizonatlBarChart;
