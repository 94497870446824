import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import { Check } from 'lucide-react'
import db from "../../../../db/index.json";
import { useSelector, useDispatch } from "react-redux";
import { ShowError } from "../../../../utils/ToasFunction";
import { ShowSuccess } from "../../../../utils/ToasFunction";
import MyLoader from "../../../../component/reusebale/myLoader"
import BaseUrl from "../../../../constants/BaseUrl"
import * as AuthAction from "../../../../store/actions/auth/AuthAction"
const PersonalForm = (props) => {
  const { loginSuccess, userCompleteProfile } = useSelector((state) => state.auth)
  const { completeProfileData } = db;
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [formFields, setFormFields] = useState({
    email: loginSuccess?.user?.email,
    firstName: loginSuccess?.user?.firstName,
    lastName: loginSuccess?.user?.lastName,
    landloardId: loginSuccess?.user?._id,
    emailVerified: true,
    phoneNumberVerified: userCompleteProfile?.personalInformation ? true : false,
    phoneNumber: userCompleteProfile?.personalInformation ? userCompleteProfile.personalInformation.phoneNumber : "",
    profileImage: "https://img.freepik.com/free-photo/artist-white_1368-3546.jpg",

    phoneOpt: userCompleteProfile?.personalInformation ? "00000" : "",
    optSendShow: true


  })






  const UpdateProfileInformation = (imageUrl) => {

    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "landloardId": formFields.landloardId,
      "personalInformation": {
        "firstName": formFields.firstName,
        "lastName": formFields.lastName,
        "landloardId": formFields.landloardId,
        "email": formFields.email,
        "emailVerified": true,
        "phoneNumber": Number(formFields.phoneNumber),
        "phoneNumberVerified": formFields.phoneNumberVerified,
        "profileImage": imageUrl ? imageUrl : null
      }
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };


    fetch(`${BaseUrl}landlord/profile/createNewProfile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success") {
          setLoading(false)
          ShowSuccess("Personal Information updated successfully")
          dispatch(AuthAction.GetLandlordProfile(loginSuccess?.user?._id))
        } else {
          setLoading(false)
          ShowSuccess(result.message)
        }
      })
      .catch((error) => console.error(error));
  }

  const submitHandler = (e) => {


    e.preventDefault();
    if (userCompleteProfile.approvedStatus === "sendForApproval") {
      ShowError("You cannot change anything on your profile unitil admin approved your request")
    } else {
      if (formFields.phoneNumber === "") {
        ShowError("Please enter your cell number first")
      } else if (formFields.phoneOpt === "") {
        ShowError("Phone number is not verified")
      } else if (!props.uploadedImageUrl) {
        ShowError("Image is required")
      } else {
        UpdateProfileInformation(props.uploadedImageUrl)
      }
    }


  }

  return (
    <div className="bg-white rounded-xl">
      <div className="w-[95%] mx-auto py-8">
        <ParagraphTag
          content={completeProfileData?.PersonalInformation?.title}
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />

        <form className="mt-3">
          <div class="w-full mt-3">
            <LabelTag name={completeProfileData?.PersonalInformation?.lable4} className="text-black" />
            <div className="relative flex justify-end">
              <input
                value={formFields.email}
                type="text"
                placeholder="Enter your email address.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
              <div className="absolute top-[15px] mx-2 bg-statusColor w-4 h-4 rounded-full flex justify-center items-center ">
                <Check color="white" size={11} />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[49%] mt-3">
              <LabelTag name={completeProfileData?.PersonalInformation?.lable2} className="text-black" />
              <input
                value={formFields?.firstName}
                type="text"
                placeholder="Enter your first name.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="w-[49%] mt-3">
              <LabelTag name={completeProfileData?.PersonalInformation?.lable3} className="text-black" />
              <input
                value={formFields?.lastName}
                type="text"
                placeholder="Enter your email last name.."
                className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-[49%] mt-3">
              <LabelTag name={completeProfileData?.PersonalInformation?.lable5} className="text-black" />
              <div className="w-full relative">
                <input
                  disabled={formFields.phoneNumberVerified}
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      phoneNumber: e.target.value
                    })
                  }}
                  value={formFields.phoneNumber}
                  type="text"
                  placeholder="Enter your Phone Number.."
                  className="w-full mt-1 font-popinsRegular bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 pr-16"
                />
                <div className="absolute top-[6px] right-[2px]">


                  <ButtonTag
                    disabled={formFields.phoneNumberVerified}
                    onSubmit={(e) => {
                      e.preventDefault();
                      ShowSuccess("OTP has sent successfully.")
                      setFormFields({
                        ...formFields,
                        optSendShow: false
                      })
                    }}

                    name={completeProfileData?.PersonalInformation?.phoneOtpSend}
                    classes={`text-xs px-2 bg-themeColor hover:${formFields.phoneNumberVerified ? "bg-black/90" : ":bg-themeColor/90"}  text-center text-white ${formFields.phoneNumberVerified ? "bg-gray-200" : "bg-themeColor"}`}
                  />
                </div>
              </div>
            </div>
            <div className="w-[49%] mt-3">
              <LabelTag name="OTP" className="text-black" />
              <div className="w-full relative">
                <input
                  disabled={formFields.phoneNumberVerified}
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      phoneOpt: e.target.value
                    })
                  }}
                  value={formFields.phoneOpt}
                  type="text"
                  placeholder="Enter 4 digit OTP.."
                  className="w-full mt-1 font-popinsRegular bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 pr-16"
                />
                <div className="absolute top-[6px] right-[2px]">
                  <ButtonTag
                    disabled={formFields.phoneNumberVerified}
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (formFields.phoneOpt === "") {
                        ShowError("Otp cannot be empty")
                      } else if (formFields.phoneOpt === "12345") {
                        ShowSuccess("Cell number verified successfully")
                        setFormFields({
                          ...formFields,
                          phoneNumberVerified: true
                        })
                      }
                    }}
                    name={formFields.phoneNumberVerified ? "verified" : "verify"}
                    classes={`text-xs px-2 ${formFields.phoneNumberVerified ? 'bg-green-600' : "bg-themeColor"}  hover:bg-themeColor/90 text-center text-white`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-themeColor text-white mt-5 rounded-md">
            {
              loading ? <div className="flex w-full justify-center items-center py-2">
                <MyLoader />
              </div> : <ButtonTag onSubmit={submitHandler} name={userCompleteProfile?.personalInformation ? "Completed" : "Update"} />
            }


          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalForm;
