import React from "react";

const RecentPayment = () => {

  return (
    <div>
        recent
    </div>
  );
};

export default RecentPayment;
