import React from "react";
import LabelTag from "../reusebale/label";
import { Link } from 'react-router-dom';
import db from "../../db/index.json";
import ButtonTag from "../reusebale/button";
import ParagraphTag from '../reusebale/Paragraph'
import MyLoader from "../../component/reusebale/myLoader"

const ForgetFormSection = (props) => {
  const { verification } = db;
  return (
    <form className="mt-8">
      <div className="w-full mt-3 flex flex-col lg:flex-row lg:justify-between">
        <div class="w-full lg:w-[48%]">
          <LabelTag name={verification.emailLabel} isStaric={false} />
          <div className="w-full relative">
            <input
              value={props.emailValue}
              disabled
              type="text"
              placeholder="Enter your email.."
              className="w-full mt-1 font-popinsRegular bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 pr-16"
            />
            <div className="absolute top-[6px] right-[2px]">
              <ButtonTag onSubmit={props.onVerify} name={verification.verify} classes='text-xs px-2 bg-themeColor hover:bg-themeColor/90 text-center text-white' />
            </div>
          </div>
        </div>
        <div class="w-full lg:w-[48%] mt-4 lg:mt-0">
          <LabelTag name={verification.otpLabel} isStaric={false} />
          <div className="w-full relative">
            <input
              onChange={props.onChangeOtp}
              value={props.optValue}
              type="text"
              placeholder="Enter OTP"
              className="w-full mt-1 font-popinsRegular bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
            <div className="absolute top-[6px] right-[2px]">
              {
                props.loader ? <MyLoader />
                  :
                  <ButtonTag onSubmit={props.onVerifyOtp} name={verification.otpSubmit} classes='text-xs px-2 bg-themeColor hover:bg-themeColor/90 text-center text-white' />
              }

            </div>
          </div>
        </div>
      </div>
      <Link to={"/reset-password"}>
        <ButtonTag name='Verify' classes='mt-5 text-base text-center bg-themeColor text-white mb-8 lg:mb-0' />
      </Link>
      {/* <ButtonTag name={verification.verifyMainBtn} classes='text-base bg-themeColor hover:bg-themeColor/90 mt-5 text-center text-white' /> */}
      <div className="w-full text-center pt-5">
        <ParagraphTag content={verification.msgOtp} classes='text-[#686868] font-popinsRegular text-sm' />
        <ParagraphTag content={verification.resend} classes='text-themeColor font-popinsRegular text-sm cursor-pointer' />
      </div>
      <div className="flex items-center my-2">
        <div className="w-[15%] lg:w-[30%] bg-[#1A1A1A]/30 h-[1px]"></div>
        <div className="w-[70%] lg:w-[40%] flex justify-center">
          <ParagraphTag content='Login to a different account' classes='text-[#686868] font-popinsRegular text-sm' />
        </div>
        <div className="w-[15%] lg:w-[30%] bg-[#1A1A1A]/30 h-[1px]"></div>
      </div>
      <Link to={"/login"}>
        <ButtonTag name='Log In' classes='text-base text-center border-2 border-themeColor text-themeColor mb-8 lg:mb-0' />
      </Link>
    </form>
  );
};

export default ForgetFormSection;
