import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import ImageTag from "../../reusebale/imageTag";
import {Clock4,ArrowRight,CircleAlert} from 'lucide-react'

const MaintenanceRequestSection = () => {
  const { rightPanel } = db;

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="flex justify-between items-center ">
        <ParagraphTag
          content={rightPanel.requests}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.viewall}
          classes="text-themeColor font-popinsSemiBold text-sm cursor-pointer"
        />
      </div>
      <div className="flex border-b-[1px] border-darkColor/40 pb-1 mt-6">
        <div className="w-[50%]">
          <ParagraphTag
            content={rightPanel.tenants}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-center ">
          <ParagraphTag
            content={rightPanel.status}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
        <div className="w-[25%] text-end">
          <ParagraphTag
            content={rightPanel.quick}
            classes="text-darkColor/40 font-popinsMedium text-sm"
          />
        </div>
      </div>
      {rightPanel.tableData.map((item, i) => (
        <div className="flex mt-4 justify-between">
          <div className="w-[49%] flex items-center">
            <div className="w-[16%]">
              <ImageTag
                path="/assets/daashboard/setting-icon.png"
                classes="w-full"
                altText="login"
              />
            </div>
            <div className="w-[84%] pl-3">
              <ParagraphTag
                content={item.rowDesc}
                classes="text-darkColor/40 font-popinsMedium text-xs"
              />
              <ParagraphTag
                content={item.rowName}
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
            </div>
          </div>
          <div className="w-[29%] flex items-center justify-center">
            {item.inStatus === 'in progress' &&
              <Clock4 color="#01CB78" size={20}/>
            }
            { item.inStatus === 'Overdue' &&
              <CircleAlert color="#E43B3B" size={20}/>
            }
            <ParagraphTag
                content={item.inStatus}
                classes={`${item.inStatus === 'in progress' ? 'text-statusColor' : 'text-dangerColor'} font-popinsMedium text-sm pl-2`}
              />
          </div>
          <div className="w-[19%] flex justify-center items-center">
            <div className="flex justify-center items-center bg-themeColor w-[35%] h-8 rounded-lg cursor-pointer">
              <ArrowRight color="#FFFFFF" size={20} strokeWidth={2}/>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MaintenanceRequestSection;
