import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import { CircleAlert } from "lucide-react";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
const Tenants = () => {

  const [formField, setFormFields] = useState({
    firstName: "",
    lastName: "",
    tenentsEmail: "",
    password: "1234@Find",
    cellNumber: "",
    preeTenent: false
  })

  const NextHandler = (e) => {
    e.preventDefault();
    if (formField.firstName === "") {
      ShowError("Tenant's first name is required")
    } else if (formField.lastName === "") {
      ShowError("Tenant's last name is required")
    } else if (formField.tenentsEmail === "") {
      ShowError("Tenant's email is required")
    } else if (formField.cellNumber === "") {
      ShowError("Tenant's Cell number is required")
    } else {
      alert(JSON.stringify(formField))
    }
  }


  return (
    <div className="w-[40%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Lease Tenant"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Assign tenants"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll flex flex-col items-center px-1">
        <div className="w-full">
          <LabelTag

            name="Tenant"
            isStaric={false}
            className="text-darkColor text-left !text-xs !font-popinsMedium"
            classes="!text-xs !font-popinsMedium"
          />
          <select className="block w-full bg-bgColor px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm mt-1">
            <option value="" disabled>
              -- Select a State --
            </option>
            <option>1949 Linda Street</option>
          </select>
        </div>
        <div className="h-[1px] w-full bg-darkColor/30 my-3"></div>
        <div className="w-full">
          <LabelTag
            isStaric={false}
            name="Tenant’s First Name"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formField.firstName}
            onChange={(e) => {
              setFormFields({
                ...formField,
                firstName: e.target.value
              })
            }}
            type="text"
            placeholder="Enter tenant's first name here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="w-full mt-3">
          <LabelTag

            isStaric={false}
            name="Tenant’s Last Name"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formField.lastName}
            onChange={(e) => {
              setFormFields({
                ...formField,
                lastName: e.target.value
              })
            }}
            type="text"
            placeholder="Enter tenant's last name here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="w-full mt-3">
          <LabelTag
            isStaric={false}
            name="Tenant’s Email Address"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formField.tenentsEmail}
            onChange={(e) => {
              setFormFields({
                ...formField,
                tenentsEmail: e.target.value
              })
            }}
            type="text"
            placeholder="Enter tenant's email here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>

        <div className="w-full mt-3">
          <LabelTag
            isStaric={false}
            name="Tenant’s Cell Number"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formField.cellNumber}
            onChange={(e) => {
              setFormFields({
                ...formField,
                cellNumber: e.target.value
              })
            }}
            type="text"
            placeholder="Enter tenant's cell number here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="w-full mt-3">
          <LabelTag
            isStaric={false}
            name="Tenant’s Password"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formField.password}
            type="password"
            placeholder="Enter tenant's cell password here"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="bg-[#F4F4F4] flex w-full py-4 rounded-md my-3">
          <div className="w-[15%] flex justify-center">
            <CircleAlert color="#B3B3B3" size={35} />
          </div>
          <div className="w-[85%]">
            <ParagraphTag
              content="The tenant will be provided a temporary system generated password. "
              classes="text-[#B3B3B3] font-popinsRegular text-xs w-[85%]"
            />
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={NextHandler}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Tenants;
