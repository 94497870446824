import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";

const CollerInfo = () => {
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[15%] flex flex-col justify-center">
        <ParagraphTag
          content="Caller Info"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <div>
          <ParagraphTag
            content="lorem ipsum"
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
      </div>
      <form className="overflow-y-scroll h-[75%] panelScroll px-1">
          <div className=" w-[40%] mx-auto">
            <div className="mt-3">
              <LabelTag isStaric={false} name="Caller Name" classes="!text-xs !font-popinsMedium"/>
              <input
                type="text"
                placeholder="text here.."
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="mt-2">
              <LabelTag isStaric={false} name="Caller Phone" classes="!text-xs !font-popinsMedium"/>
              <input
                type="text"
                placeholder="text here.."
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
            <div className="mt-2">
              <LabelTag isStaric={false} name="Caller Email" classes="!text-xs !font-popinsMedium" />
              <input
                type="text"
                placeholder="text here.."
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
            </div>
          </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto mt-4 mb-2">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default CollerInfo;
