import React, { useEffect, useState } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import PlanSection from '../profile-complete/plans'
import { useSelector } from "react-redux"
const PlanStatus = ({ onButtonClick }) => {
  const { completeProfileData } = db;
  const { userCompleteProfile } = useSelector((state) => state.auth)
  const [profielPercentage, setProfilePercentage] = useState(1)

  useEffect(() => {
    if (userCompleteProfile) {
      if (userCompleteProfile.personalInformation && !userCompleteProfile.companyInformation) {
        setProfilePercentage(40)
      } else if (userCompleteProfile.companyInformation && !userCompleteProfile.personalInformation) {
        setProfilePercentage(40)
      } else if (userCompleteProfile.companyInformation && userCompleteProfile.personalInformation) {
        setProfilePercentage(80)
      }
    }
  }, [userCompleteProfile])



  return (
    <div className="w-full pt-4">
      <div>
        <ParagraphTag
          content={completeProfileData.complete}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={completeProfileData.profileDesc}
          classes="text-[#686868] font-popinsRegular text-sm w-[45%] pt-1"
        />
        <div class="w-[100%] h-4 mb-4 bg-[#E6BF9F33] rounded-full dark:bg-[#E6BF9F33] mt-4">
          <div
            class="h-4 bg-themeColor rounded-full dark:bg-themeColor"
            style={{ width: `${profielPercentage}%` }}
          >
          </div>
        </div>
      </div>
      <PlanSection onButtonClick={onButtonClick} />
    </div>
  );
};

export default PlanStatus;
