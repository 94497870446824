import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import { Send } from "lucide-react";

const Chat = () => {
  const [messages, setMessages] = useState([
    {
      sender: "Bastian Schweinsteiger",
      text: "Hey, I need help",
      time: "1:15 PM",
      senderType: "user",
    },
    {
      sender: "Support",
      text: "Hi there, how may I help you?",
      time: "1:15 PM",
      senderType: "support",
    },
  ]);
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim()) {
      setMessages([
        ...messages,
        {
          sender: "Bastian Schweinsteiger",
          text: input,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          senderType: "user",
        },
      ]);
      setInput("");
    }
  };

  return (
    <div className="bg-white rounded-lg h-[85vh] flex flex-col">
      {/* Header */}
      <div className="text-center pt-4">
        <h2 className="text-lg font-popinsSemiBold text-darkColor">Chat</h2>
        <p className="text-darkColor/40 font-popinsRegular text-xs">
          Lorem Ipsum
        </p>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-6">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex flex-col ${
              msg.senderType === "user" ? "items-start" : "items-end"
            }`}
          >
            {/* Sender Name */}
            <p className="text-sm font-popinsBold text-darkColor mb-1">
              {msg.sender}
            </p>
            {/* Message Bubble */}
            <div
              className={`max-w-xs p-3 rounded-lg ${
                msg.senderType === "user"
                  ? "bg-gray-100 text-left"
                  : "bg-orange-100 text-right"
              }`}
            >
              <p className="text-sm font-popinsRegular text-darkColor">
                {msg.text}
              </p>
            </div>
            <span className="text-xs font-popinsRegular text-darkColor/40 mt-1">
              {msg.time}
            </span>
          </div>
        ))}
      </div>

      {/* Input */}
      <div className="bg-white p-4 flex items-center gap-2 sticky bottom-0">
        <input
          type="text"
          placeholder="Type here"
          className="w-full mt-1 text-sm font-popinsRegular bg-bgColor px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
        />
        <button
          onClick={handleSend}
          className="bg-orange-400 text-white p-2 rounded-lg hover:bg-orange-500 transition"
        >
          <Send size={18} />
        </button>
      </div>
    </div>
  );
};

export default Chat;
