import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import HeadingTag from "../../reusebale/heading";
import db from "../../../db/index.json";
import { Bell } from "lucide-react";
import { useSelector } from "react-redux"

const HeaderSection = ({ onNotificationClick }) => {
  const { rightPanel } = db;
  const { loader, loginSuccess, loginError } = useSelector((state) => state.auth)

  return (
    <div className="w-full pt-4">
      <div className="flex justify-between items-center">
        <div className="">
          <ParagraphTag
            content={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName} `}
            classes="text-black/40 font-popinsMedium text-sm"
          />
          <HeadingTag
            title={rightPanel.welcomeMsg}
            classes="font-popinsSemiBold text-black text-2xl mt-[-4px]"
          />
        </div>
        <div>
          <div className="w-10 h-10 bg-white rounded-full flex justify-center items-center relative cursor-pointer" onClick={onNotificationClick}>
            <Bell size={22} />
            <div className="bg-themeColor w-2 h-2 rounded-full absolute right-[10px] top-[10px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
