import React from "react";
import RecordSection from '../home/record-section'
import MaintenanceRequestSection from '../home/maintainance-request'
import ReceivePayment from '../home/receivePayment'
import TenantsSection from '../home/tenantHome'
import BalancesSection from '../home/balances'
import PropertiesSection from '../home/propertiesHome'
import RenewelSection from '../home/leaseRenewel'
import OccupancyRate from '../home/occupancyRate'
import StatusChart from '../home/stats'
import HeaderSection from '../home/homeHeader'


const HomeSection = ({ onNotificationClick }) => {


  return (
    <div className="w-full mb-8">
      <HeaderSection onNotificationClick={onNotificationClick}/>
      <RecordSection />
      <div className="flex justify-between">
        <div className="w-[50%]">
          <MaintenanceRequestSection />
          <RenewelSection />
          <ReceivePayment />
          <TenantsSection />
        </div>
        <div className="w-[49%]">
          <OccupancyRate />
          <StatusChart />
          <BalancesSection />
          <PropertiesSection />
        </div>
      </div>
    </div>
  );
};

export default HomeSection;
