import React, { useState, useEffect } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import AddUnitPopup from "../../../popup/unitpopup";
import AddPropertyPopup from "../../../popup/addPropertyPopup";
import { useSelector } from "react-redux";
import BaseUrl from "../../../../constants/BaseUrl";
import { ChevronDown } from "lucide-react";

const Terms = () => {
  // const { allProperties } = useSelector((state) => state.property)
  const [allProperties, setAllProperties] = useState([]);
  const [showUnitPopUp, setShowUnitpopUp] = useState(false);
  const [showPropertyPopUp, setshowPropertypopUp] = useState(false);

  const { loginSuccess } = useSelector((state) => state.auth);
  const [formFields, setFormFields] = useState({
    selectedProperty: null,
    unitList: [],
    selectedUnit: "",
    selectedPropertyName: "",
    startData: null,
    endData: null,
  });

  const GetAllProperties = () => {
    const requestOptions = {
      method: "GET",

      redirect: "follow",
    };

    fetch(
      `${BaseUrl}api/property/getAllPropertiesbyLandlord/${loginSuccess?.user?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setAllProperties(result.data);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetAllProperties();
  }, []);

  const PropertySelectHandler = (event) => {
    const selectedProperty = allProperties.find(
      (i) => i.propertyName === event.target.value
    );
    setFormFields({
      ...formFields,
      selectedProperty: selectedProperty,
      unitList: selectedProperty.unitData,
      selectedPropertyName: selectedProperty.propertyName,
    });
    // alert(JSON.stringify(selectedProperty))
  };

  const UnitSelectHandler = (event) => {
    const UnitName = event.target.value;
    setFormFields({
      ...formFields,
      selectedUnit: UnitName,
    });
  };

  const openPopup = () => {
    setShowUnitpopUp(true);
  };

  const closePopup = () => {
    setShowUnitpopUp(false);
  };

  const openPropertyPopup = () => {
    setshowPropertypopUp(true);
  };

  const closePropertyPopup = () => {
    setshowPropertypopUp(false);
  };

  return (
    <div className="w-[60%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Property Unit"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Choose your desired property and unit."
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>

      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="flex justify-between mt-6">
          <div className="w-[49%]">
            <LabelTag
              name="Property"
              isStaric={false}
              classes="text-darkColor text-left !text-xs !font-popinsMedium"
            />
            <div
              className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
              onClick={openPropertyPopup}
            >
              <p className="font-popinsRegular text-darkColor/40">1949 Linda Street</p>
              <ChevronDown color="#1A1A1A2D" size={18} />
            </div>
            {/* <select
              onFocus={() => {
                setFormFields({
                  ...formFields,
                  selectedProperty: "",
                  unitList: [],
                  selectedUnit: "",
                  selectedPropertyName: "",
                });
              }}
              onChange={PropertySelectHandler}
              className="block w-full bg-bgColor px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm mt-1"
            >
              <option value={formFields.selectedPropertyName} disabled>
                Select a property
              </option>
              {allProperties.map((i) => {
                return <option>{i.propertyName}</option>;
              })}
            </select> */}
          </div>
          <div className="w-[49%]">
            <LabelTag
              name="Unit"
              isStaric={false}
              classes="text-darkColor !text-left !text-xs !font-popinsMedium"
            />
            <div
              className="w-full bg-bgColor py-[10px] rounded-md text-sm px-3 cursor-pointer flex justify-between"
              onClick={openPopup}
            >
              <p className="font-popinsRegular text-darkColor/40">Unit# 14</p>
              <ChevronDown color="#1A1A1A2D" size={18} />
            </div>
            {/* <select
              onChange={UnitSelectHandler}
              className="block w-full bg-bgColor px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm mt-1"
            >
              <option value={formFields?.selectedUnit}>
                -- Select a unit --
              </option>
              {formFields.unitList.map((i) => {
                return <option>{i.unitName}</option>;
              })}
            </select> */}
          </div>
        </div>
        <div className="text-center mt-6">
          <ParagraphTag
            content="Lease Terms"
            classes="text-darkColor font-popinsSemiBold text-base"
          />
          <ParagraphTag
            content="Select the duration of the lease."
            classes="text-darkColor/50 font-popinsMedium text-xs"
          />
        </div>
        <div className="flex justify-center py-4">
          <div className="bg-[#E43B3B1A] rounded-lg px-10 py-2 hover:bg-themeColor hover:text-white cursor-pointer">
            <p className="text-xs font-popinsMedium">Pre Rent</p>
          </div>
          <div className="bg-[#E43B3B1A] rounded-lg px-10 py-2 mx-3 hover:bg-themeColor hover:text-white cursor-pointer">
            <p className="text-xs font-popinsMedium">Post Rent</p>
          </div>
          <div className="bg-[#E43B3B1A] rounded-lg px-10 py-2 hover:bg-themeColor hover:text-white cursor-pointer">
            <p className="text-xs font-popinsMedium">One Time</p>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Start Date"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="date"
              placeholder="Please select end date"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              onChange={(event) => {
                setFormFields({
                  ...formFields,
                  startData: event.target.value,
                });
              }} // Handle date change
              value={formFields.startData} // Bind selectedDate to value
            />
          </div>
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="End Date"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="date"
              placeholder="Please select end date"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
              onChange={(event) => {
                setFormFields({
                  ...formFields,
                  endData: event.target.value,
                });
              }} // Handle date change
              value={formFields.endData} // Bind selectedDate to value
            />
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            onSubmit={(e) => {
              e.preventDefault();
              alert(JSON.stringify(new Date(formFields.startData)));
            }}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
      {showUnitPopUp && <AddUnitPopup onClose={closePopup} />}
      {showPropertyPopUp && <AddPropertyPopup onClose={closePropertyPopup} />}
    </div>
  );
};

export default Terms;
