import React, { useState } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import ReactPaginate from "react-paginate";
import {
  Plus,
  ArrowUpDown,
  ArrowDownUp,
  Clock4,
  CircleAlert,
  CircleCheck,
  ArrowRight,
  User
} from "lucide-react";
import db from "../../../db/index.json";

const LeaseTable = ({ onViewDetails, onViewCreate }) => {
  const { maintainances } = db;

  const [data, setData] = useState([
    {
      id: 1,
      ID: "MRID-001238",
      name: "Broken light",
      status: "in progress",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$699.87",
      priority: "High Priority",
    },
    {
      id: 2,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Overdue",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$7,386.05",
      priority: "Low Priority",
    },
    {
      id: 3,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
    {
      id: 4,
      ID: "MRID-001238",
      name: "Broken light",
      status: "in progress",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$699.87",
      priority: "High Priority",
    },
    {
      id: 5,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Overdue",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$7,386.05",
      priority: "Low Priority",
    },
    {
      id: 6,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
    {
      id: 7,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
    {
      id: 8,
      ID: "MRID-001238",
      name: "Broken light",
      status: "in progress",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$699.87",
      priority: "High Priority",
    },
    {
      id: 9,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Overdue",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$7,386.05",
      priority: "Low Priority",
    },
    {
      id: 10,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
    {
      id: 11,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
    {
      id: 12,
      ID: "MRID-001238",
      name: "Broken light",
      status: "in progress",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$699.87",
      priority: "High Priority",
    },
    {
      id: 13,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Overdue",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$7,386.05",
      priority: "Low Priority",
    },
    {
      id: 14,
      ID: "MRID-001238",
      name: "Broken light",
      tatus: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
    {
      id: 15,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  return (
    <div className="h-full pt-5">
      <div className="bg-white w-full h-[87%]">
        <div className="w-[95%] mx-auto h-full">
          <div className="flex justify-between items-center h-[12%]">
            <div className="w-[60%]">
              <ParagraphTag
                content={maintainances.lease}
                classes="text-darkColor font-popinsSemiBold text-base"
              />
            </div>

            <div className="flex justify-between w-[40%]">
              <button className="border-themeColor border w-[48%] rounded-md flex items-center justify-center h-9">
              <User color="#FF814E" size={18}/>
                <ParagraphTag
                  content={maintainances.tenantRequest}
                  classes="text-themeColor font-popinsSemiBold text-xs pl-2"
                />
              </button>
              
              <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center h-9" onClick={onViewCreate}>
                <Plus color="#FFFFFF" size={15} />
                <ParagraphTag
                  content={maintainances.new}
                  classes="text-white font-popinsSemiBold text-xs pl-2"
                />
              </button>
            </div>
          </div>
          <div className="overflow-x-scroll w-full h-[88%] panelScroll pt-2">
            <table className="w-full ">
              <thead className="sticky top-0 bg-white">
                <tr className="border-b border-black/20">
                  <th className="w-[25%] text-left pb-2">
                    <span className="flex">
                      <ParagraphTag
                        content={maintainances.thead1}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "lease" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("lease")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("lease")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead2}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "status" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[25%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead3}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "related" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("related")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("related")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("related")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[10%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead4}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                      />
                      {sortConfig.key === "cost" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("cost")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("cost")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("cost")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%] pb-2">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={maintainances.thead5}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "priority" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("priority")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("priority")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("priority")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[20%] text-right pr-4 pb-2">
                    <ParagraphTag
                      content={maintainances.thead6}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item) => (
                  <tr
                    key={item.id}
                    className="hover:bg-gray-100 rounded-md cursor-pointer"
                  >
                    <td className="py-2">
                      <div className="flex items-center justify-between">
                        <div className="w-[18%] rounded-full">
                          <ImageTag
                            path="/assets/daashboard/setting-icon.png"
                            classes="w-full"
                            altText="logo"
                          />
                        </div>
                        <div
                          className="w-[78%] cursor-pointer"
                          onClick={onViewDetails}
                        >
                          <ParagraphTag
                            content={item.ID}
                            classes="text-darkColor/50 font-popinsMedium text-xs"
                          />
                          <ParagraphTag
                            content={item.name}
                            classes="text-darkColor font-popinsSemiBold text-sm"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="py-2 font-popinsMedium text-darkColor text-sm">
                      <div className="flex justify-center items-center">
                        <span>
                          {(() => {
                            switch (item.status) {
                              case "Overdue":
                                return (
                                  <CircleAlert color="#E43B3B" size={20} />
                                );
                              case "in progress":
                                return <Clock4 color="#B3B3B3" size={20} />;
                              case "Completed":
                                return (
                                  <CircleCheck color="#01CB78" size={20} />
                                );
                              default:
                                break;
                            }
                          })()}
                        </span>
                        <span className={`pl-2 ${item.status === 'Overdue' ? 'text-dangerColor' : item.status === 'in progress' ? 'text-[#B3B3B3]' : 'text-[#01CB78]'}`}>{item.status}</span>
                      </div>
                    </td>
                    <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      <div className="flex flex-col">
                        <span className="text-darkColor/50 text-xs">
                          {item.propertyId}
                        </span>
                        <span className="text-sm text-darkColor">
                          {item.propertyName}
                        </span>
                      </div>
                    </td>
                    <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      {item.cost}
                    </td>
                    <td className="py-2 px-4 font-popinsMedium text-darkColor text-xs text-center">
                      <div
                        className={`bg-[#E43B3B1A] rounded-full py-1 ${
                          item.priority === "Low Priority"
                            ? "text-[#687AEE]"
                            : item.priority === "Regular Priority"
                            ? "text-[#B3B3B3]"
                            : "text-[#E43B3B]"
                        }`}
                      >
                        {item.priority}
                      </div>
                    </td>
                    <td className="pt-5 2xl:pt-4 px-4 flex justify-center items-center h-full">
                      <div className="flex justify-center items-center border border-themeColor w-7 h-7 rounded-md cursor-pointer">
                        <ArrowRight
                          color="#6F6F6F"
                          size={18}
                          strokeWidth={2}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center h-[13%]">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="flex space-x-2 items-center"
          pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
          previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
          nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
          disabledClassName="opacity-50 !cursor-not-allowed"
          activeClassName="bg-themeColor text-white"
          breakClassName="px-3 py-1 text-sm font-popinsMedium"
        />
      </div>
    </div>
  );
};

export default LeaseTable;
