import React from "react";
import db from "../../../db/index.json";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";

const MapSection = () => {
  const { properties } = db;

  return (
    <div className="bg-white rounded-lg px-4 py-5">
      <ParagraphTag
        content={properties.heading}
        classes="text-darkColor font-popinsSemiBold text-lg"
      />
      <ImageTag
        path="/assets/daashboard/map.png"
        classes="w-full object-cover mt-5"
        altText="logo"
      />
    </div>
  );
};

export default MapSection;
