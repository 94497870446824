import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import LabelTag from "../../../reusebale/label";
import { Plus, Trash } from "lucide-react";
import db from "../../../../db/index.json";
import IconCards from "../../../reusebale/iconCards";

const RentCharges = () => {
  const [Funds, setFunds] = useState(db.properties.fundsData);
  const handleCardClick = (name) => {
    const updatedTypes = Funds.map((property) =>
      property.title === name
        ? { ...property, active: true }
        : { ...property, active: false }
    );

    setFunds(updatedTypes);
  };

  return (
    <div className="w-[60%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Rent Charges"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Lorem Ipsum"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="w-full flex justify-between">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Rent Date"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="date"
              placeholder="Type"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[49%]">
            <LabelTag
              name="Frequency"
              isStaric={false}
              className="text-darkColor text-left !text-xs !font-popinsMedium"
              classes="!text-xs !font-popinsMedium"
            />
            <select className="block w-full bg-bgColor px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm mt-1">
              <option value="" disabled>
                -- Select a State --
              </option>
              <option>Monthly</option>
            </select>
          </div>
        </div>
        <div className="text-center pt-6">
          <ParagraphTag
            content="Charge Late Fee?"
            classes="text-darkColor font-popinsSemiBold text-base"
          />
          <ParagraphTag
            content="Will you be holding a security deposit on this lease?"
            classes="text-darkColor/50 font-popinsMedium text-xs"
          />
        </div>
        <div className="flex w-[40%] mx-auto justify-between mt-4">
          {Funds.map((item) => (
            <div
              key={item.title}
              className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
              onClick={() => handleCardClick(item.title)}
            >
              <IconCards
                mainClasses={`h-36 w-[130px] ${
                  item.active ? "border-red-500 border-2" : ""
                }`}
                imgClasses="w-[40px]"
                path={item.active ? item.imagePathActiv : item.imagePath}
                title={item.title}
                titleClasses={
                  item.active ? "text-themeColor" : "text-darkColor"
                }
              />
            </div>
          ))}
        </div>
        <div className="h-[1px] bg-darkColor/20 mt-7"></div>
        <div className="w-full flex justify-between mt-5">
          <div className="w-[5%] flex items-center pt-4">
            <ParagraphTag
              content="1"
              classes={`text-themeColor font-popinsSemiBold pt-1 text-xs`}
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Charges"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Description"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Amount"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              disabled
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="flex justify-end items-end pb-1 cursor-pointer w-[6%]">
            <div className="border-2 border-dangerColor w-7 h-7 rounded-md flex justify-center items-center">
              <Trash color="#E43B3B" size={16} />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between items-center py-5">
          <div className="w-[36%] h-[1px] bg-darkColor/20"></div>
          <div className="w-[25%]">
            <button className="flex justify-center items-center text-themeColor rounded-md border border-themeColor py-2 w-full text-xs font-popinsMedium">
              <Plus size={15} color="#FF814E" />
              <span className="pl-2">Add New Charge</span>
            </button>
          </div>
          <div className="w-[36%] h-[1px] bg-darkColor/20"></div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[50%]">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default RentCharges;
