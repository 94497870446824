import React, { useState } from "react";
import db from "../../../db/index.json";
import SearchBar from "../../reusebale/searchBar";
import LeasesTable from "../leases/leaseTable";
import FilterPopup from "../../popup/filterPopup";
import DetailMain from "./leaseDetail/main";
import CreateMain from "./createProperty/createMain";

const LeasesSection = () => {
  const { lease } = db;
  const [showFilter, setShowFilter] = useState(false);
  const [showTable, setShowTable] = useState("table");

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = () => {
    setShowTable("detail"); // State toggle karein
  };

  const toggleCreate = () => {
    setShowTable("create"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  return (
    <div>
      {/* <SearchBar title={lease.title} desc={lease.desc} onSubmit={openPopup}/>
      <LeasesTable/>
      {showFilter &&
        <FilterPopup onBack={closePopup} onFilter={closePopup}/>
      } */}
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div>
                <div className="h-[10vh]">
                  <SearchBar
                    title={lease.title}
                    desc={lease.description}
                    onSubmit={openPopup}
                  />
                </div>
                <div className="h-[90vh]">
                  <LeasesTable
                    onViewDetails={toggleDetail}
                    onViewCreate={toggleCreate}
                  />
                </div>
              </div>
            );
          case "detail":
            return <DetailMain onBack={toggleTable} />;
          case "create":
            return <CreateMain />;
          default:
            break;
        }
      })()}
      {showFilter && <FilterPopup onBack={closePopup} onFilter={closePopup} />}
    </div>
  );
};

export default LeasesSection;
