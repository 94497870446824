import React from "react";

const NewProperties = () => {

  return (
    <div>
        properties
    </div>
  );
};

export default NewProperties;
