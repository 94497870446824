import React from "react";
import db from "../../db/index.json";
import ParagraphTag from "../reusebale/Paragraph";
import ImageTag from "../reusebale/imageTag";
import ButtonTag from "../reusebale/button";
import { Search } from "lucide-react";

const AddPropertyPopup = ({ onClose }) => {
  const { properties } = db;
  return (
    <main className="w-full h-screen backdrop-blur-sm bg-black/80 fixed inset-0 z-50 flex items-center justify-center">
      <section className="w-[90%] sm:w-[65%] md:w-[50%] lg:w-[40%] xl:w-[40%] bg-texture myshades rounded-[31px] mx-auto">
        <div class="bg-white py-8 lg:py-5 rounded-xl justify-center items-center flex flex-col h-[80vh] ">
          <div className="h-[15%] w-[90%] mx-auto flex-col justify-between flex">
            <p className="text-darkColor font-popinsSemiBold text-base text-center">
              Add Property
            </p>
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search"
                className="w-full font-popinsRegular mt-1 bg-[#E6BF9F4D] pl-4 py-[10px] text-xs rounded-lg text-darkColor placeholder:text-zinc-700/50"
              />
              <div className="absolute top-[16px] right-4">
                <Search color="#1A1A1A5D" size={15} />
              </div>
            </div>
          </div>
          <div className="pt-5 h-[80%] overflow-y-scroll w-[90%] mx-auto panelScroll">
            {/* {properties.unitData.map((item, i) => (
              <UnitsCard data={item} classes="w-full my-2 py-3 px-2" />
            ))} */}
            <div className="flex border-b border-darkColor/20 pb-1">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Properties"
                  classes="text-darkColor/50 font-popinsMedium text-xs"
                />
              </div>
              <div className="w-[50%] text-end">
                <ParagraphTag
                  content="Quick Actions"
                  classes="text-darkColor/50 font-popinsMedium text-xs"
                />
              </div>
            </div>
            {/* musharaf yaha pr loop chala lena tm */}
            <div className="flex items-center">
              <div className="w-[70%]">
                <div className="flex items-center justify-between">
                  <div className="w-[18%] rounded-full">
                    <ImageTag
                      path="/assets/daashboard/property/property1.png"
                      classes="w-full"
                      altText="logo"
                    />
                  </div>

                  <div className="w-[78%] cursor-pointer">
                    <ParagraphTag
                      content="PID-000199581"
                      classes="text-darkColor/50 font-popinsMedium text-xs"
                    />
                    <ParagraphTag
                      content="1949 Linda Street"
                      classes="text-darkColor font-popinsSemiBold text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[30%] text-end">
                <ButtonTag
                  name="Select"
                  classes="text-sm text-center border border-themeColor text-themeColor"
                />
              </div>
            </div>
            {/* musharaf yaha tak loop m rkhna */}
          </div>
          <div className="h-[5%] pt-3">
            <p
              className="cursor-pointer font-popinsMedium text-darkColor text-center text-sm"
              onClick={onClose}
            >
              Close
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddPropertyPopup;
