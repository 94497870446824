// DashboardChart.jsx
import React from "react";
import HorizonatlBarChart from "./dashborad-charts/horizonatalBar"; // Import HorizontalChart component
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";

const RenewelSection = () => {
  const { rightPanel } = db;

  return (
    <div className="dashboard-chart-container bg-white rounded-2xl mt-5 py-5 px-4 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="">
        <ParagraphTag
          content={rightPanel.renewels}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={rightPanel.totalNumber}
          classes="text-darkColor font-popinsSemiBold text-xl pt-5"
        />
        
      </div>

      {/* Include HorizontalChart here */}
      <div className="mt-8">
        <HorizonatlBarChart />
      </div>
    </div>
  );
};

export default RenewelSection;
