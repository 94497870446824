import React from "react";
import OverviewRequest from "./overviewRequest";
import Chat from "./chat";
import Breadcrumbs from "../../../reusebale/breadcrumbs";

const DetailMain = ({ onBack }) => {
  return (
    <div className="mt-4">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        subheading="My Account"
        content="MRID-001238"
        onBack={onBack}
      />
      <div className="flex justify-between mt-4">
        <div className="w-[64%]">
          <OverviewRequest />
        </div>
        <div className="w-[34%]">
          <Chat />
        </div>
      </div>
    </div>
  );
};

export default DetailMain;
