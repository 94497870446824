import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";

const InfoSection = () => {


  return (
    <div className="bg-white mt-5 rounded-xl py-4 px-5">
        <ParagraphTag
          content="Broadcasts"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <div className="pt-3">
          <ParagraphTag
            content="Property"
            classes={`text-darkColor font-popinsSemiBold text-sm `}
          />
          <ParagraphTag
            content="2323 Dancing Dove Lane, Long Island City, NY 11101"
            classes={`text-darkColor/40 font-popinsRegular text-xs `}
          />
          <ParagraphTag
            content="Description"
            classes={`text-darkColor font-popinsSemiBold text-sm pt-3`}
          />
          <ParagraphTag
            content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt molestie, massa nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac habitasse platea dictumst. Vivamus vehicula leo dui, at porta nisi facilisis finibus. In euismod augue vitae nisi ultricies, non aliquet urna tincidunt. Integer in nisi eget nulla commodo faucibus efficitur quis massa. Praesent felis est, finibus et nisi ac, hendrerit venenatis libero. Donec consectetur faucibus ipsum id gravida."
            classes={`text-darkColor/40 font-popinsRegular text-xs `}
          />
        </div>
      </div>
  );
};

export default InfoSection;
