import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";

const PropertyInfo = () => {
  return (
    <div className="bg-white rounded-xl">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Property Info"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1 items-center">
        <div className="w-[30%]">
          <ImageTag
            path="/assets/daashboard/property/property4.png"
            classes="w-[230px] rounded-full"
            altText="logo"
          />
        </div>
        <div className="w-[70%] px-2">
          <div className="pt-3">
            <ParagraphTag
              content="Complete Address"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content="2323 Dancing Dove Lane, Long Island City, NY 2323"
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2 flex">
            <div className="w-[50%]">
              <ParagraphTag
                content="City"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content="Long Island City"
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
            <div className="w-[50%]">
              <ParagraphTag
                content="Country"
                classes="text-darkColor font-popinsSemiBold text-sm"
              />
              <ParagraphTag
                content="United States"
                classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
              />
            </div>
          </div>
          <div className="pt-2">
            <ParagraphTag
              content="Unit"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content="Unit# 14"
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyInfo;
