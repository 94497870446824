import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import db from "../../../db/index.json";
import PropertyTable from "../properties/propertiesTable";
import MainSection from "./property-detail/main";
import CreateProperty from "./create-property/main";
import FilterPopup from "../../popup/filterPopup";

const PropertySection = () => {
  const { properties } = db;

  const [showTable, setShowTable] = useState("table"); // Default: Table show ho raha hai

  const [showFilter, setShowFilter] = useState(false);


  const [propertyId, setPropertyId] = useState()

  const openPopup = () => {
    setShowFilter(true);
  };

  const closePopup = () => {
    setShowFilter(false);
  };

  const toggleDetail = (id) => {

    setPropertyId(id)
    setShowTable("detail"); // State toggle karein
  };


  const toggleCreate = () => {
    setShowTable("create"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div className="h-screen w-full">
                <div className="h-[10vh]">
                  <SearchBar
                    title={properties.title}
                    desc={properties.desc}
                    onSubmit={openPopup}
                  />
                </div>
                <div className="h-[90vh]">
                  <PropertyTable
                    onViewDetails={(id) => {

                      toggleDetail(id)
                    }}
                    onViewCreate={toggleCreate}
                  />
                </div>
              </div>
            );
          case "detail":
            return <MainSection propertyId={propertyId} onBack={toggleTable} />;
          case "create":
            return <CreateProperty onBack={toggleTable} />;
          default:
            break;
        }
      })()}
      {showFilter && <FilterPopup onBack={closePopup} onFilter={closePopup} />}
    </div>
  );
};

export default PropertySection;
