import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import db from "../../../../db/index.json";

const Recipent = () => {
  const { broadcasts } = db;

  return (
    <div className="bg-white mt-4 rounded-xl py-4 px-5">
        <ParagraphTag
          content="Recipient"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <div className="flex justify-between flex-wrap mt-5">
        {broadcasts.recipient.map((item) => (
          <div className="w-[24%] flex  bg-yellow-100">
            <div className="w-[20%] rounded-full">
              <ImageTag
                path={item.image}
                classes="w-full"
                altText="logo"
              />
            </div>
            <div className="text-center pt-2 px-1">
              <ParagraphTag
                content={item.desc}
                classes="text-darkColor/50 font-popinsMedium text-xs"
              />
              <ParagraphTag
                content={item.title}
                classes="text-darkColor font-popinsSemiBold text-sm mt-[-2px]"
              />
            </div>
          </div>
        ))}
        </div>
      </div>
  );
};

export default Recipent;
