import React, { useEffect, useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ImageTag from "../../../reusebale/imageTag";
import ButtonTag from "../../../reusebale/button";
import { Camera, Trash } from "lucide-react";
import BaseUrl from "../../../../constants/BaseUrl";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { ShowError, ShowSuccess } from "../../../../utils/ToasFunction";
import { useDispatch, useSelector } from "react-redux";
import MyLoader from "../../../reusebale/myLoader";
import * as PropertyAction from "../../../../store/actions/property/PropertyAction";
const Address = () => {
  const dispatch = useDispatch();
  const { createNewPropertyBody } = useSelector((state) => state.property);
  const { allCitiesAndStates } = useSelector((state) => state.data);

  const [formData, setFormData] = useState({
    propertyName: "",
    compleAddress: "",
    streetOne: "",
    streetTwo: "",
    city: "",
    state: "",
    country: "United State",
    zipCode: "",
    langitude: "28.9900",
    latitude: "29.4900",
    thumbNailImage: null,
    imagesList: [],
    thumbnailLoader: false,
    imagesLoader: false,
  });

  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  useEffect(() => {
    // Log the data when component mounts or `allCitiesAndStates` changes
    console.log("Cities and States Data:", allCitiesAndStates);
  }, [allCitiesAndStates]);

  useEffect(() => {
    if (createNewPropertyBody.completeAddress) {
      setFormData({
        ...formData,
        propertyName: createNewPropertyBody.propertyName,
        compleAddress: createNewPropertyBody.completeAddress,
        streetOne: createNewPropertyBody.completeAddress,
        streetTwo: createNewPropertyBody.streetTwo,
        state: createNewPropertyBody.state,
        country: createNewPropertyBody.country,
        zipCode: createNewPropertyBody.zipCode,
        langitude: createNewPropertyBody.langitude,
        latitude: createNewPropertyBody.latitude,
        thumbNailImage: createNewPropertyBody.thumbnailImage,
        imagesList: createNewPropertyBody.images,
        city: createNewPropertyBody.city,
      });
    }
  }, []);

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setFormData({
      ...formData,
      state: e.target.value,
    });

    // Find the selected state's cities and set in the state
    const stateData = allCitiesAndStates.find((item) => item.state === state);
    setCities(stateData ? stateData.cities : []); // Update cities
  };

  const UploadImagesToServer = (imagesList) => {
    setFormData({
      ...formData,
      imagesLoader: true,
    });

    const formdata = new FormData();

    // Append each image to the FormData object
    imagesList.forEach((image, index) => {
      formdata.append(`images`, image); // Ensure "images" matches the server's expected key
    });

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/propertyImage/upload-multiple`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.files) {
          setFormData({
            ...formData,
            imagesLoader: false,
            imagesList: result?.files,
          });
        } else {
          ShowError(
            "Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB"
          );
        }
      })
      .catch((error) => {
        setFormData({
          ...formData,
          imagesLoader: false,
        });
        ShowError(
          "Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB"
        );
      });
  };

  // Handle image selection
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    UploadImagesToServer(files);
  };

  const ThumbNailImage = (imageData) => {
    setFormData({
      ...formData,
      thumbnailLoader: true,
    });

    const formdata = new FormData();
    formdata.append("image", imageData);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseUrl}api/propertyImage/upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.url) {
          setFormData({
            ...formData,
            thumbnailLoader: false,
            thumbNailImage: result,
          });
        } else {
          ShowError(
            "Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB"
          );
        }
      })
      .catch((error) => {
        setFormData({
          ...formData,
          thumbnailLoader: false,
        });
        ShowError(
          "Oops! Something went wrong. Please upload an image in JPEG or PNG format with a maximum size of 2MB"
        );
      });
  };

  // Trigger file input on icon click
  const handleIconClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleIconClickThumbnail = () => {
    document.getElementById("fileInput2").click();
  };

  const NextHandler = (e) => {
    e.preventDefault();
    if (formData.compleAddress === "") {
      ShowError("Address cannot be empty");
    } else if (formData.propertyName === "") {
      ShowError("Property name cannot be empty");
    } else if (formData.streetOne === "") {
      ShowError("Street one cannot be empty");
    } else if (formData.streetTwo === "") {
      ShowError("Street two cannot be empty");
    } else if (formData.state === "") {
      ShowError("State cannot be empty");
    } else if (formData.city === "") {
      ShowError("City cannot be empty");
    } else if (formData.zipCode === "") {
      ShowError("Zip code cannot be empty");
    } else if (!formData.thumbNailImage) {
      ShowError("Thumbnail is required");
    } else if (formData.imagesList.length === 0) {
      ShowError("Unit pictures required");
    } else {
      dispatch(PropertyAction.ChangePropertyCreateSteps(3));
      dispatch(PropertyAction.CreatePropertyStepTwoAction(formData));
    }

    // alert(JSON.stringify(createNewPropertyBody))
  };

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Add New Properties"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <div>
          <ParagraphTag
            content="Which kind of property do you have?"
            classes="text-[#686868] font-popinsRegular text-xs"
          />
        </div>
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div>
          <LabelTag
            isStaric={false}
            name="Property Name"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formData.propertyName}
            onChange={(e) => {
              setFormData({
                ...formData,
                propertyName: e.target.value,
              });
            }}
            type="text"
            placeholder="Please enter property name"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="mt-2">
          <LabelTag
            isStaric={false}
            name="Complete Address"
            classes="!text-xs !font-popinsMedium"
          />
          <input
            value={formData.compleAddress}
            onChange={(e) => {
              setFormData({
                ...formData,
                compleAddress: e.target.value,
              });
            }}
            type="text"
            placeholder="Please enter your complete address"
            className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
          />
        </div>
        <div className="flex justify-between mt-2">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Street 1"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={formData.streetOne}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  streetOne: e.target.value,
                });
              }}
              type="text"
              placeholder="Please enter your street 1.."
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="street 2"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={formData.streetTwo}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  streetTwo: e.target.value,
                });
              }}
              type="text"
              placeholder="Please enter your street 2"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="State"
              classes="!text-xs !font-popinsMedium"
            />
            <select
              value={selectedState}
              onChange={handleStateChange}
              className="block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm bg-bgColor"
            >
              <option value="" disabled>
                -- Select a State --
              </option>
              {allCitiesAndStates.map((item, index) => (
                <option key={index} value={item.state}>
                  {item.state}
                </option>
              ))}
            </select>
          </div>
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="City"
              classes="!text-xs !font-popinsMedium"
            />
            <select
              value={formData.city}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  city: e.target.value,
                });
              }}
              className="block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor sm:text-sm bg-bgColor"
              disabled={!selectedState || cities.length === 0} // Disable if no state selected
            >
              <option value="" disabled>
                {cities.length === 0
                  ? "Select a state first"
                  : "-- Select a City --"}
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex justify-between mt-2">
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Country"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={formData.country}
              type="text"
              placeholder="Please enter your Country.."
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[49%]">
            <LabelTag
              isStaric={false}
              name="Zip Code"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              value={formData.zipCode}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  zipCode: e.target.value,
                });
              }}
              type="text"
              placeholder="Please enter your zip"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <div className="w-[35%] flex">
            <div className="w-[47%] h-[120px]">
              {formData.thumbnailLoader ? (
                <div className="flex justify-center items-center h-[120px]">
                  <MyLoader />
                </div>
              ) : (
                <div
                  className={`${
                    formData?.thumbNailImage?.url
                      ? ""
                      : "border-dashed border-2  border-[#B3B3B3]"
                  } flex flex-col items-center justify-center w-full bg-slate-100 rounded-md cursor-pointer`}
                >
                  {formData?.thumbNailImage?.url ? (
                    <div className="relative">
                      <img
                        src={formData?.thumbNailImage?.url}
                        alt=""
                        className="w-full h-[120px] object-cover"
                      />
                      <div className="absolute top-0 right-0">
                        <div className="bg-dangerColor rounded-md cursor-pointer w-7 h-7 flex justify-center items-center">
                          <Trash color="#FFFF" size={18} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="flex flex-col justify-center items-center h-[120px]"
                      onClick={handleIconClickThumbnail}
                    >
                      <Camera
                        className="text-darkColor/50"
                        size={35}
                        strokeWidth={2}
                      />
                      <ParagraphTag
                        content="Upload Thumbnail"
                        classes="pt-1 text-darkColor/50 font-popinsRegular text-xs"
                      />
                      <input
                        type="file"
                        id="fileInput2"
                        multiple
                        accept="image/*"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          if (file) {
                            ThumbNailImage(file);
                          }
                        }}
                        className="hidden"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex items-center w-[6%] justify-center">
              <div className="bg-orangeColor h-20 w-[1px]"></div>
            </div>

            <div className="flex w-[47%] h-[120px]">
              {formData.imagesLoader ? (
                <div className="flex items-center h-[120px] justify-center">
                  <MyLoader />{" "}
                </div>
              ) : (
                <div
                  className="flex flex-col items-center justify-center w-full bg-slate-100 rounded-md cursor-pointer border-dashed border-2  border-[#B3B3B3]"
                  onClick={handleIconClick}
                >
                  <Camera
                    className="text-darkColor/50"
                    size={35}
                    strokeWidth={2}
                  />
                  <ParagraphTag
                    content="Upload"
                    classes="pt-1 text-darkColor/50 font-popinsRegular text-xs"
                  />
                  <input
                    type="file"
                    id="fileInput"
                    multiple
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-[63%] overflow-hidden">
            {formData?.imagesList?.length > 0 && (
              <CarouselProvider
                naturalSlideWidth={33.33} // 3 images per slide, each taking 33.33% of the container width
                naturalSlideHeight={20} // Set height of images to 120px
                totalSlides={formData.imagesList.length}
                visibleSlides={3} // Display 3 images at once
                infinite
                step={1} // Slide one by one
                isDragEnabled={formData?.imagesList?.length > 3} // Enable drag functionality
                dragVelocity={2} // Adjust drag speed (optional)
              >
                <div className="relative w-full">
                  <Slider>
                    {formData.imagesList.map((image, index) => (
                      <Slide key={index} index={index}>
                        <div className="w-[80%] h-[120px] relative overflow-hidden">
                          <img
                            src={image.imageUrl}
                            alt={`Slide ${index}`}
                            className="w-full h-full object-cover" // Ensures images cover the full height and width
                          />
                          <div className="absolute top-0 right-0">
                            <div className="bg-dangerColor rounded-md cursor-pointer w-7 h-7 flex justify-center items-center">
                              <Trash color="#FFFF" size={18} />
                            </div>
                          </div>
                        </div>
                      </Slide>
                    ))}
                  </Slider>
                </div>
              </CarouselProvider>
            )}
          </div>
        </div>

        <div className="mt-4">
          <ImageTag
            classes="rounded-lg"
            path={"/assets/daashboard/map-overview.png"}
            altText="logo"
          />
        </div>
      </form>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[40%] mx-auto">
          <ButtonTag
            onSubmit={NextHandler}
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
