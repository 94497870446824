import React, { useState } from "react";
import PlanStatus from '../profile-complete/planStatus'
import SubscriptionPlan from './subscription'
import PersonalInfo from '../profile-complete/personal-information/personal-info'
import CompanyInfo from '../profile-complete/company-information/company-info'
import RecentPayment from '../profile-complete/recent-pay'
import NewProperties from '../profile-complete/add-properties'
import NewLeases from '../profile-complete/add-leases'

const MainSection = () => {
  
  const [activeComponent, setActiveComponent] = useState("PlanStatus"); // Default: PlanStatus show

  const handleButtonClick = (buttonName) => {
    setActiveComponent(buttonName); // Button ka naam state mein set karein
  };

  const handleBackToPlanStatus = () => {
    setActiveComponent("PlanStatus"); // PlanStatus ko wapas laane ke liye
  };

  return (
    <div className="">
      
      {/* <PlanStatus onButtonClick={handleButtonClick} />

      {activeComponent === "Subscription Plan" && <SubscriptionPlan/>} */}
      {/* Conditional Rendering */}
      {activeComponent === "PlanStatus" ? (
        <PlanStatus onButtonClick={handleButtonClick} />
      ) : activeComponent === "Subscription Plan" ? (
        <SubscriptionPlan onBack={handleBackToPlanStatus} />
      ) : activeComponent === "Personal Information" ? (
        <PersonalInfo onBack={handleBackToPlanStatus} />
      ) : activeComponent === "Company Information" ? (
        <CompanyInfo onBack={handleBackToPlanStatus}/>
      ) : activeComponent === "Recent Payment from tenants" ? (
        <RecentPayment onBack={handleBackToPlanStatus}/>
      ) : activeComponent === "Add New Properties" ? (
        <NewProperties onBack={handleBackToPlanStatus}/>
      ) : activeComponent === "Add your leases" ? (
        <NewLeases onBack={handleBackToPlanStatus}/>
      ) : null}
    </div>
  );
};

export default MainSection;
