export const LoginActionConst = {
    USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    USER_LOGIN_SUCC: "USER_LOGIN_SUCC",
    USER_LOGIN_FAIL: "USER_LOGIN_FAIL",
}


export const SignUpActionConst = {
    USER_SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
    USER_SIGNUP_SUCC: "USER_SIGNUP_SUCC",
    USER_SIGNUP_FAIL: "USER_SIGNUP_FAIL",
}


export const GetLandlordProfileActionConst = {
    GET_USER_PROFILE_REQ: "GET_USER_PROFILE_REQ",
    GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
    GET_USER_PROFILE_FAIL: "GET_USER_PROFILE_FAIL",
    CLEAR_ALL_STATUS: "CLEAR_ALL_STATUS"

}

export const ClearSignUpFieldsActionConst = {
    SIGNUP_CLEAR: "SIGNUP_CLEAR",
}



export const SendProfileForApprovalActionConst = {
    SEND_REQUEST_FOR_APPROVAL_REQ: "SEND_REQUEST_FOR_APPROVAL_REQ",
    SEND_REQUEST_FOR_APPROVAL_SUCC: "SEND_REQUEST_FOR_APPROVAL_SUCC",
    SEND_REQUEST_FOR_APPROVAL_FAIL: "SEND_REQUEST_FOR_APPROVAL_FAIL",
}

export const ProfileCompletedActionConst = {
    PROFILE_COMPLETED: "PROFILE_COMPLETED",

}