import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import {
  Plus,
  History,
  ArrowUpDown,
  ArrowDownUp,
  CircleCheck,
  Clock4,
  ArrowRight,
  CircleAlert
} from "lucide-react";
import db from "../../../../db/index.json";

const MaintainanceSection = () => {
  const { maintainances } = db;
  const [data, setData] = useState([
    {
      id: 1,
      ID: "MRID-001238",
      name: "Broken light",
      status: "in progress",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$699.87",
      priority: "High Priority",
    },
    {
      id: 2,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Overdue",
      propertyId: "PID-000199581",
      propertyName: "1949 Linda Street",
      cost: "$7,386.05",
      priority: "Low Priority",
    },
    {
      id: 3,
      ID: "MRID-001238",
      name: "Broken light",
      status: "Completed",
      propertyId: "PID-000199581",
      propertyName: "3522 West Fork Street",
      cost: "$7,386.05",
      priority: "Regular Priority",
    }
  ]);

  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  return (
    <div className="bg-white rounded-lg mt-5">
      <div className="w-[95%] mx-auto py-6">
        <div className="flex">
          <div className="w-[65%]">
            <ParagraphTag
              content="Maintenance Requests"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
          </div>
          <div className="w-[35%] flex justify-between">
            <button className="w-[48%] border border-themeColor rounded-md flex items-center justify-center py-2">
              <History color="#FF814E" size={15} />
              <ParagraphTag
                content="Past Lease"
                classes="text-themeColor font-popinsSemiBold text-xs pl-2"
              />
            </button>
            <button className="w-[48%] bg-themeColor rounded-md flex items-center justify-center py-2">
              <Plus color="#FFFFFF" size={15} />
              <ParagraphTag
                content="New Task"
                classes="text-white font-popinsSemiBold text-xs pl-2"
              />
            </button>
          </div>
        </div>
        <div className="w-full mt-5">
          <table className="w-full ">
            <thead className="sticky top-0 bg-white">
              <tr className="border-b border-black/20">
                <th className="w-[25%] text-left pb-2">
                  <span className="flex">
                    <ParagraphTag
                      content={maintainances.thead1}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "lease" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("lease")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("lease")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[15%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead2}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "status" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("status")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[25%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead3}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "related" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("related")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("related")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("related")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[10%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead4}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                    />
                    {sortConfig.key === "cost" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("cost")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("cost")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("cost")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[15%] pb-2">
                  <span className="flex justify-center">
                    <ParagraphTag
                      content={maintainances.thead5}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                    />
                    {sortConfig.key === "priority" ? (
                      sortConfig.direction === "asc" ? (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("priority")}
                        />
                      ) : (
                        <ArrowDownUp
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("priority")}
                        />
                      )
                    ) : (
                      <ArrowUpDown
                        className="cursor-pointer"
                        size={13}
                        color="#6F6F6F"
                        onClick={() => requestSort("priority")}
                      />
                    )}
                  </span>
                </th>
                <th className="w-[20%] text-right pr-4 pb-2">
                  <ParagraphTag
                    content={maintainances.thead6}
                    classes="text-darkColor/40 font-popinsSemiBold text-xs"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 rounded-md cursor-pointer"
                >
                  <td className="py-2">
                    <div className="flex items-center justify-between">
                      <div className="w-[18%] rounded-full">
                        <ImageTag
                          path="/assets/daashboard/setting-icon.png"
                          classes="w-full"
                          altText="logo"
                        />
                      </div>
                      <div
                        className="w-[78%] cursor-pointer"
                      >
                        <ParagraphTag
                          content={item.ID}
                          classes="text-darkColor/50 font-popinsMedium text-xs"
                        />
                        <ParagraphTag
                          content={item.name}
                          classes="text-darkColor font-popinsSemiBold text-sm"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="py-2 font-popinsMedium text-darkColor text-sm">
                    <div className="flex justify-center items-center">
                      <span>
                        {(() => {
                          switch (item.status) {
                            case "Overdue":
                              return <CircleAlert color="#E43B3B" size={20} />;
                            case "in progress":
                              return <Clock4 color="#B3B3B3" size={20} />;
                            case "Completed":
                              return <CircleCheck color="#01CB78" size={20} />;
                            default:
                              break;
                          }
                        })()}
                      </span>
                      <span
                        className={`pl-2 ${
                          item.status === "Overdue"
                            ? "text-dangerColor"
                            : item.status === "in progress"
                            ? "text-[#B3B3B3]"
                            : "text-[#01CB78]"
                        }`}
                      >
                        {item.status}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    <div className="flex flex-col">
                      <span className="text-darkColor/50 text-xs">
                        {item.propertyId}
                      </span>
                      <span className="text-sm text-darkColor">
                        {item.propertyName}
                      </span>
                    </div>
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-sm text-center">
                    {item.cost}
                  </td>
                  <td className="py-2 px-4 font-popinsMedium text-darkColor text-xs text-center">
                    <div
                      className={`bg-[#E43B3B1A] rounded-full py-1 ${
                        item.priority === "Low Priority"
                          ? "text-[#687AEE]"
                          : item.priority === "Regular Priority"
                          ? "text-[#B3B3B3]"
                          : "text-[#E43B3B]"
                      }`}
                    >
                      {item.priority}
                    </div>
                  </td>
                  <td className="py-2 px-4 flex justify-center font-popinsMedium text-darkColor text-sm">
                    <div className="flex justify-center items-center border border-themeColor w-7 h-7 rounded-md cursor-pointer">
                      <ArrowRight color="#6F6F6F" size={18} strokeWidth={2} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MaintainanceSection;
