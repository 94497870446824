import React from "react";
import Breadcrumbs from "../../reusebale/breadcrumbs";
import ParagraphTag from "../../reusebale/Paragraph"
import ImageTag from '../../reusebale/imageTag'
import db from "../../../db/index.json";
import {Grid2x2} from "lucide-react"


const Notification = ({ onBack }) => {
  const { notification } = db;

  return (
    <div className="mt-6 w-full">
      <Breadcrumbs classes={`text-darkColor font-popinsSemiBold text-xl`} content={notification.title} onBack={onBack} subheading={notification.subTitle}/>

      <div className="bg-white mt-5 rounded-xl">
        <div className="w-[95%] mx-auto pt-5 ">
          <div className="flex justify-between">
            <ParagraphTag
              content={notification.heading}
              classes={`text-darkColor font-popinsSemiBold text-lg `}
            />
            <button
              className="border-[#6F6F6F]/70 border rounded-md flex items-center justify-center px-4 py-2 text-sm font-popinsMedium text-[#6F6F6F]"
            >
              <Grid2x2 color='#6F6F6F' size={15}/>
              <span className="pl-2">Switch layout</span>
            </button>
          </div>
          <div className="flex flex-wrap justify-between mt-3">
            {notification.notiData.map((item, i) => (
              <div className="w-[49%] my-2 py-2 bg-slate-50 flex pl-4 items-center rounded-md">
                <ImageTag classes={`w-[38px] h-[38px]`} path={'/assets/daashboard/notification-color.png'} altText='logo' />
                <div className="px-2">
                  <ParagraphTag
                    content={item.titleSmall}
                    classes={`text-orangeColor font-popinsSemiBold text-xs `}
                  />
                  <ParagraphTag
                    content={item.heading}
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />
                  
                  <ParagraphTag
                    content={item.description}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

  );
};

export default Notification;
