import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import React from 'react'
import Login from "../pages/auth/login"
import Signup from "../pages/auth/signup"
import ForgetPassword from "../pages/auth/forgetPassword"
import ResetPassword from "../pages/auth/resetPassword"
import Verification from "../pages/auth/verification"
import Dashboard from "../pages/dashboard/main-dashboard"
import Middleware from "../pages/Middleware"
const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Middleware />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/account-verification" element={<Verification />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        </Routes>
    )
}

export default MainRoutes