import React, { useState } from "react";
import db from "../../../../db/index.json";
import IconCards from "../../../reusebale/iconCards";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";
import { File,CircleAlert } from "lucide-react";

const LateFee = () => {
  const [Funds, setFunds] = useState(db.properties.fundsData);
  const [uploadDoc, setuploadDoc] = useState(true);

  const handleCardClick = (name) => {
    const updatedTypes = Funds.map((property) =>
      property.title === name
        ? { ...property, active: true }
        : { ...property, active: false }
    );
    if (name === "No") {
      setuploadDoc(false);
    } else {
      setuploadDoc(true);
    }

    setFunds(updatedTypes);
  };

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="h-[10%] text-center">
        <ParagraphTag
          content="Define your lease"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Is your lease signed"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>
      <div className="overflow-y-scroll h-[80%] panelScroll flex flex-col items-center pb-5">
        <div className="flex w-[40%] mx-auto justify-between mt-6">
          {Funds.map((item) => (
            <div
              key={item.title}
              className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
              onClick={() => handleCardClick(item.title)}
            >
              <IconCards
                mainClasses={`h-36 w-[130px] ${
                  item.active ? "border-red-500 border-2" : ""
                }`}
                imgClasses="w-[40px]"
                path={item.active ? item.imagePathActiv : item.imagePath}
                title={item.title}
                titleClasses={
                  item.active ? "text-themeColor" : "text-darkColor"
                }
              />
            </div>
          ))}
        </div>

        <div className="w-[40%] mx-auto  my-5">
          <div className="bg-black/10 h-[1px]"></div>
        </div>
        {uploadDoc ? (
          <div className="text-center w-full">
            <ParagraphTag
              content="Upload Lease Document"
              classes="text-darkColor font-popinsSemiBold text-lg"
            />
            <ParagraphTag
              content="(Optional)"
              classes="text-darkColor/50 font-popinsMedium text-xs"
            />
            <div className="flex justify-between w-[70%] mx-auto mt-6">
              <div className="w-[49%] bg-[#F4F4F4] rounded-md flex flex-col items-center py-14 cursor-pointer">
                <File color="#B3B3B3" size={30} />
                <ParagraphTag
                  content="Upload  Lease Document"
                  classes="text-[#B3B3B3] font-popinsMedium text-xs pt-1"
                />
              </div>
              <div className="w-[49%] bg-[#F4F4F4] rounded-md flex flex-col items-center py-14 cursor-pointer">
                <File color="#B3B3B3" size={30} />
                <ParagraphTag
                  content="Upload Signature"
                  classes="text-[#B3B3B3] font-popinsMedium text-xs pt-1"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-[#F4F4F4] flex items-center w-[70%] mx-auto py-4 rounded-md my-3">
            <div className="w-[14%] flex justify-end pr-3">
              <CircleAlert color="#B3B3B3" size={35} />
            </div>
            <div className="w-[86%]">
              <ParagraphTag
                content="Always make sure your late fee policy is the same as in your actual lease agreement and that it is compliant with your state and local laws."
                classes="text-[#B3B3B3] font-popinsRegular text-xs"
              />
            </div>
          </div>
        )}
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default LateFee;
