import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";

const LeaveNotes = () => {
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[15%] flex flex-col justify-center">
        <ParagraphTag
          content="Leave Notes"
          classes={`text-darkColor font-popinsSemiBold mt-[-19px] text-lg`}
        />
        <div>
          <ParagraphTag
            content="lorem ipsum"
            classes={`text-paraColor font-popinsRegular text-xs`}
          />
        </div>
      </div>
      <form className="overflow-y-scroll h-[75%] panelScroll px-1">
        <div className=" w-[80%] mx-auto">
          <div className="mt-2">
            <LabelTag isStaric={false} name="Tech Note" classes="!text-xs !font-popinsMedium"/>
            <textarea
              type="text"
              cols={10}
              placeholder="text here.."
              className="w-full h-24 mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            ></textarea>
          </div>
          <div className="mt-2">
            <LabelTag isStaric={false} name="Access Note" classes="!text-xs !font-popinsMedium"/>
            <textarea
              type="text"
              cols={10}
              placeholder="text here.."
              className="w-full h-24 mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            ></textarea>
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto mt-4 mb-2">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default LeaveNotes;
