import React from "react";


const HeadingTag=(props) => {
  const { title, classes } = props;

  return (
    <h1 className={`${classes}`}>{title}</h1>
  );
}

export default HeadingTag;
