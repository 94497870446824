import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import LabelTag from "../../../reusebale/label";
import ButtonTag from "../../../reusebale/button";
import { Camera, Trash } from "lucide-react";

const Details = () => {
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="Request Details"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
        <ParagraphTag
          content="lorem ipsum"
          classes="text-[#686868] font-popinsRegular text-xs"
        />
      </div>
      <form className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className=" w-[70%] mx-auto">
          <div className="flex justify-between pt-2">
            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Property"
                classes="!text-xs !font-popinsMedium"
              />
              <select
                value=""
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                <option value="Select1">Select 1</option>
                <option value="Select2">Select 2</option>
                <option value="Select3">Select 3</option>
              </select>
            </div>
            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Units"
                classes="!text-xs !font-popinsMedium"
              />
              <select
                value=""
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                <option value="Select1">Select 1</option>
                <option value="Select2">Select 2</option>
                <option value="Select3">Select 3</option>
              </select>
            </div>
          </div>
          <div className="pt-2">
            <LabelTag
              isStaric={false}
              name="Description"
              classes="!text-xs !font-popinsMedium"
            />
            <textarea
              type="text"
              cols={10}
              placeholder="Please enter your description"
              className="w-full h-20 mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            ></textarea>
          </div>
          <div className="pt-2">
            <LabelTag
              isStaric={false}
              name="Category"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="text"
              placeholder="Please enter your category"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="flex justify-between pt-2">
            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Date"
                classes="!text-xs !font-popinsMedium"
              />
              <select
                value=""
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                <option value="Select1">Select 1</option>
                <option value="Select2">Select 2</option>
                <option value="Select3">Select 3</option>
              </select>
            </div>
            <div className="w-[49%]">
              <LabelTag
                isStaric={false}
                name="Due Date"
                classes="!text-xs !font-popinsMedium"
              />
              <select
                value=""
                className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-lg text-darkColor placeholder:text-zinc-700/50"
              >
                <option value="Select1">Select 1</option>
                <option value="Select2">Select 2</option>
                <option value="Select3">Select 3</option>
              </select>
            </div>
          </div>
          <div className="pt-4 flex">
            <div className="border-dashed border-2  border-[#B3B3B3] text-center flex flex-col items-center justify-center w-[100px] h-[100px] bg-slate-100 rounded-md cursor-pointer">
              <Camera className="text-darkColor/50" size={35} strokeWidth={2} />
              <ParagraphTag
                content="Upload Thumbnail"
                classes="pt-1 text-darkColor/50 font-popinsRegular text-xs"
              />
            </div>
            <div className="flex">
              <div className="w-[100px] h-[100px] mx-3 bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
                <div className="border-2 border-dangerColor w-8 h-8 rounded-md flex justify-center items-center">
                  <Trash color="#E43B3B" size={18} />
                </div>
              </div>
              <div className="w-[100px] h-[100px] mx-3 bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
                <div className="border-2 border-dangerColor w-8 h-8 rounded-md flex justify-center items-center">
                  <Trash color="#E43B3B" size={18} />
                </div>
              </div>
              <div className="w-[100px] h-[100px] bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
                <div className="border-2 border-dangerColor w-8 h-8 rounded-md flex justify-center items-center">
                  <Trash color="#E43B3B" size={18} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto mt-4 mb-2">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
