import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import { Check, X } from "lucide-react";

const SubscriptionPlan = ({ onBack }) => {
  const { completeProfileData } = db;

  return (
    <div className="w-full h-screen flex items-center">
      <div className="w-[70%] mx-auto">
        <div className="flex justify-center items-center flex-col">
        <ParagraphTag
          content="Subscription Plan"
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <ParagraphTag
          content={completeProfileData.profileDesc}
          classes="text-[#686868] font-popinsRegular text-center text-sm w-[60%] pt-1"
        />
        </div>
        <div className="flex justify-between mt-3">
          {completeProfileData.subscriptionPlan.map((item, i) => (
            <div key={i} className="w-[32%] cursor-pointer mt-5 rounded-xl bg-white group hover:bg-themeColor transition-all duration-600 transform hover:scale-105 hover:shadow-lg" onClick={onBack}>
              <div className="mt-6 w-[90%] mx-auto">
                <div className="border-b-2 border-darkColor/20 pb-6 group-hover:border-white">
                  <ParagraphTag
                    content={item.package}
                    classes="text-themeColor font-popinsMedium text-xs group-hover:text-white transition-colors duration-300"
                  />
                  <div className="flex items-center">
                    <ParagraphTag
                      content={item.price}
                      classes="text-black font-popinsSemiBold text-xl group-hover:text-white transition-colors duration-300"
                    />
                    { item.price !== 'Free' &&
                      <ParagraphTag
                      content='/monthly'
                      classes="text-black font-popinsMedium text-xs group-hover:text-white transition-colors duration-300"
                      />
                    }

                  </div>

                  <ParagraphTag
                    content={item.description}
                    classes="text-darkColor/40 pt-0 font-popinsRegular text-xs group-hover:text-white/70 transition-colors duration-300"
                  />
                </div>
                {item.packageDetail.map((pakItem, i) => (
                  <div className="mt-3 flex">
                    { pakItem.isCheck &&
                      <div className="w-4 h-4 flex justify-center items-center rounded-full bg-themeColor group-hover:bg-white transition-colors duration-300">
                        <Check size={12} className="text-white group-hover:text-themeColor transition-colors duration-300"/>
                      </div>
                    }
                    { !pakItem.isCheck &&
                      <div className="w-4 h-4 flex justify-center items-center rounded-full bg-dangerColor group-hover:bg-white transition-colors duration-300">
                        <X size={12} className="text-white group-hover:text-themeColor transition-colors duration-300"/>
                      </div>
                    }
                    <ParagraphTag
                      content={pakItem.value}
                      classes="text-darkColor pl-2 font-popinsRegular text-xs group-hover:text-white transition-colors duration-300"
                    />
                  </div>
                ))}

                <div className="mt-3">
                  <button className="w-full h-9 text-white rounded-md mb-5 bg-themeColor group-hover:bg-white group-hover:text-themeColor transition-colors duration-300">
                    Select
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
