import React, { useState } from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import Terms from "./terms";
import Tenants from "./tenants";
import RentCharges from "./rentCharges";
import Deposite from "./deposite";
import LateFee from "./lateFee";
import Overview from "./overview";

const CreateMain = () => {
  const steps = ["Terms", "Tenant", "Rent", "Deposit", "Late fee", "Overview"];
  const [currentStep, setCurrentStep] = useState(1);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  return (
    <div className="h-screen">
      <div className="h-[10vh] flex flex-col justify-center">
        <ParagraphTag
          content="Leases"
          classes="text-darkColor font-popinsSemiBold text-2xl"
        />
        <ParagraphTag
          content="Renew or end leases that are expiring soon."
          classes="text-[#686868] font-popinsMedium text-sm"
        />
      </div>
      <div className="h-[90vh] py-3 ">
        <div className="bg-white rounded-md h-full">
          <div className="w-full mx-auto h-[15%]">
            {/* Stepper */}
            <div className="relative w-[70%] mx-auto flex items-center justify-between pt-5">
              {/* Step Circles */}
              {steps.map((step, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center w-full relative"
                >
                  {/* Circle */}
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${
                      currentStep === index + 1
                        ? "border-orange-500 bg-white text-orange-500"
                        : currentStep > index + 1
                        ? "border-orange-500 bg-orange-500 text-white"
                        : "border-gray-300 bg-white text-gray-500"
                    } font-popinsMedium cursor-pointer transition-all`}
                    onClick={() => handleStepClick(index + 1)}
                  >
                    {index + 1}
                  </div>

                  {/* Step Label */}
                  <p
                    className={`mt-1 text-xs font-popinsRegular text-[#686868] ${
                      currentStep >= index + 1
                        ? "text-orange-500"
                        : "text-gray-500"
                    }`}
                  >
                    {step}
                  </p>

                  {/* Line Connector */}
                  {index < steps.length - 1 && (
                    <div
                      className={`absolute top-4 left-[calc(50%+16px)] right-[calc(-50%+16px)] h-0.5 ${
                        currentStep > index + 1
                          ? "bg-orange-500"
                          : "bg-gray-300"
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="h-[85%]">
            {currentStep === 1 && <Terms />}
            {currentStep === 2 && <Tenants />}
            {currentStep === 3 && <RentCharges />}
            {currentStep === 4 && <Deposite />}
            {currentStep === 5 && <LateFee />}
            {currentStep === 6 && <Overview />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMain;
