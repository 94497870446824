import React, { useState } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import ReactPaginate from "react-paginate";
import {
  ArrowUpDown,
  ArrowDownUp,
  SquareCheck,
  SquareX,
  Bell,
  Mail,
  Phone,
} from "lucide-react";
import db from "../../../db/index.json";

const TenantsTable = ({ onViewDetails }) => {
  const { tenants } = db;

  const [data, setData] = useState([
    {
      id: 1,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 2,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
    {
      id: 3,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 4,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
    {
      id: 5,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 6,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
    {
      id: 7,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 8,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
    {
      id: 9,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 10,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
    {
      id: 11,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 12,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
    {
      id: 13,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "active",
    },
    {
      id: 14,
      name: "Lorri Warf",
      ID: "TID-000199581",
      leaseId: "PID-000199581",
      leaseProperty: "1949 Linda Street",
      Rent: "$699.87",
      phoneNumber: "(504) 896-6913",
      status: "inactive",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const paginatedData = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "lease",
    direction: "asc",
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  return (
    <div className="">
      <div className="bg-white mt-6 w-full">
        <div className="w-[95%] mx-auto">
          <ParagraphTag
            content={tenants.heading}
            classes="text-darkColor font-popinsSemiBold text-lg py-5"
          />
          <div className="overflow-x-auto w-full h-[400px] panelScroll mt-1">
            <table className="w-full ">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="w-[20%] text-left">
                    <span className="flex">
                      <ParagraphTag
                        content={tenants.heading}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "lease" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("property")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("property")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("property")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[8%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.status}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "status" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("status")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("status")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[17%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.property}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "property" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("property")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("property")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("property")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[10%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.rent}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pl-2"
                      />
                      {sortConfig.key === "rent" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("rent")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("rent")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("rent")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[15%]">
                    <span className="flex justify-center">
                      <ParagraphTag
                        content={tenants.phone}
                        classes="text-darkColor/40 font-popinsSemiBold text-xs pr-1"
                      />
                      {sortConfig.key === "phoneNumber" ? (
                        sortConfig.direction === "asc" ? (
                          <ArrowUpDown
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("phoneNumber")}
                          />
                        ) : (
                          <ArrowDownUp
                            className="cursor-pointer"
                            size={13}
                            color="#6F6F6F"
                            onClick={() => requestSort("phoneNumber")}
                          />
                        )
                      ) : (
                        <ArrowUpDown
                          className="cursor-pointer"
                          size={13}
                          color="#6F6F6F"
                          onClick={() => requestSort("phoneNumber")}
                        />
                      )}
                    </span>
                  </th>
                  <th className="w-[30%] text-right pr-4">
                    <ParagraphTag
                      content={tenants.actions}
                      classes="text-darkColor/40 font-popinsSemiBold text-xs"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item) => (
                  <tr
                    key={item.id}
                    className="hover:bg-gray-100 rounded-md cursor-pointer"
                  >
                    <td className="py-1">
                      <div className="flex items-center justify-between">
                        <div className="w-[18%] rounded-full">
                          <ImageTag
                            path="/assets/daashboard/property/property1.png"
                            classes="w-full"
                            altText="logo"
                          />
                        </div>
                        <div
                          className="w-[78%] cursor-pointer"
                          onClick={onViewDetails}
                        >
                          <ParagraphTag
                            content={item.ID}
                            classes="text-darkColor/50 font-popinsMedium text-xs"
                          />
                          <ParagraphTag
                            content={item.name}
                            classes="text-darkColor font-popinsSemiBold text-sm"
                          />
                        </div>
                      </div>
                    </td>
                    <td className="py-1 font-popinsMedium text-darkColor text-sm">
                      <span className="flex justify-center">
                        {item.status === "active" ? (
                          <SquareCheck color="#01CB78" size={20} />
                        ) : (
                          <SquareX color="#E43B3B" size={20} />
                        )}
                      </span>
                    </td>
                    <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      <div className="flex flex-col">
                        <span className="text-darkColor/50 text-xs">
                          {item.leaseId}
                        </span>
                        <span className="text-sm text-darkColor">
                          {item.leaseProperty}
                        </span>
                      </div>
                    </td>
                    <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      {item.Rent}
                    </td>
                    <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm text-center">
                      {item.phoneNumber}
                    </td>
                    <td className="py-1 px-4 font-popinsMedium text-darkColor text-sm">
                      <div className="flex justify-end">
                        <button className="flex items-center px-2 border border-[#6F6F6F] rounded-lg mr-2">
                          <Bell color="#6F6F6F" size={18} />
                        </button>
                        <button className="flex items-center px-2 border border-[#6F6F6F] rounded-lg mr-2">
                          <Phone color="#6F6F6F" size={18} />
                        </button>
                        <button className="flex items-center px-2 border border-[#6F6F6F] rounded-lg mr-2">
                          <Mail color="#6F6F6F" size={18} />
                        </button>
                        <button className="flex items-center border border-themeColor text-themeColor font-popinsSemiBold px-4 py-1 rounded text-xs">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 pr-2"
                            viewBox="0 0 15 19"
                            fill="none"
                          >
                            <path
                              d="M10.5938 2.5H12C13.2305 2.5 14.25 3.51953 14.25 4.75V16C14.25 17.2656 13.2305 18.25 12 18.25H3C1.73438 18.25 0.75 17.2656 0.75 16V4.75C0.75 3.51953 1.73438 2.5 3 2.5H4.40625H4.72266C5.00391 1.23438 6.12891 0.25 7.5 0.25C8.83594 0.25 9.99609 1.23438 10.2422 2.5H10.5938ZM3 4.1875C2.68359 4.1875 2.4375 4.46875 2.4375 4.75V16C2.4375 16.3164 2.68359 16.5625 3 16.5625H12C12.2812 16.5625 12.5625 16.3164 12.5625 16V4.75C12.5625 4.46875 12.2812 4.1875 12 4.1875H11.4375V5.03125C11.4375 5.52344 11.0508 5.875 10.5938 5.875H7.5H4.40625C3.91406 5.875 3.5625 5.52344 3.5625 5.03125V4.1875H3ZM7.5 3.90625C7.95703 3.90625 8.34375 3.55469 8.34375 3.0625C8.34375 2.60547 7.95703 2.21875 7.5 2.21875C7.00781 2.21875 6.65625 2.60547 6.65625 3.0625C6.65625 3.55469 7.00781 3.90625 7.5 3.90625Z"
                              fill="#FF814E"
                            />
                          </svg>
                          Renew Lease
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-16">
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          pageCount={Math.ceil(data.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName="flex space-x-2 items-center"
          pageClassName="px-3 py-1 border rounded-md text-sm font-popinsMedium cursor-pointer"
          previousClassName="px-3 py-1 border rounded-l-md text-sm cursor-pointer font-popinsMedium"
          nextClassName="px-3 py-1 border rounded-r-md text-sm font-popinsMedium cursor-pointer font-popinsMedium"
          disabledClassName="opacity-50 !cursor-not-allowed"
          activeClassName="bg-themeColor text-white"
          breakClassName="px-3 py-1 text-sm font-popinsMedium"
        />
      </div>
    </div>
  );
};

export default TenantsTable;
