import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import ImageTag from "../../reusebale/imageTag";
import { useSelector } from "react-redux"

const RecordSection = ({ onNotificationClick }) => {
  const { rightPanel } = db;
  const { loader, loginSuccess, loginError } = useSelector((state) => state.auth)

  return (
    <div className="w-full pt-4">
      <div className="flex bg-white rounded-2xl py-5 px-5 mt-6 w-full shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
        <div className="w-[18%] flex items-center justify-between">
          <div className="w-[25%]">
            <ImageTag
              path="/assets/daashboard/tenants-color.png"
              classes="w-full h-full"
              altText="tenants"
            />
          </div>
          <div className="w-[70%] pl-1">
            <ParagraphTag
              content={rightPanel.tenants}
              classes="text-black/40 font-popinsRegular text-sm"
            />
            <ParagraphTag
              content="23"
              classes="text-black font-popinsBold text-xl"
            />
          </div>
        </div>
        <div className="w-[18%] flex items-center justify-between">
          <div className="w-[25%]">
            <ImageTag
              path="/assets/daashboard/properties-color.png"
              classes="w-full h-full"
              altText="tenants"
            />
          </div>
          <div className="w-[70%] pl-1">
            <ParagraphTag
              content={rightPanel.properties}
              classes="text-black/40 font-popinsRegular text-sm"
            />
            <ParagraphTag
              content="14"
              classes="text-black font-popinsBold text-xl"
            />
          </div>
        </div>
        <div className="w-[18%] flex items-center justify-between">
          <div className="w-[25%]">
            <ImageTag
              path="/assets/daashboard/units-color.png"
              classes="w-full h-full"
              altText="tenants"
            />
          </div>
          <div className="w-[70%] pl-1">
            <ParagraphTag
              content={rightPanel.units}
              classes="text-black/40 font-popinsRegular text-sm"
            />
            <ParagraphTag
              content="27"
              classes="text-black font-popinsBold text-xl"
            />
          </div>
        </div>
        <div className="w-[23%] flex items-center justify-between">
          <div className="w-[20%]">
            <ImageTag
              path="/assets/daashboard/price.png"
              classes="w-full h-full"
              altText="tenants"
            />
          </div>
          <div className="w-[75%] pl-1">
            <ParagraphTag
              content={rightPanel.collected}
              classes="text-black/40 font-popinsRegular text-sm"
            />
            <ParagraphTag
              content="$25,000"
              classes="text-black font-popinsBold text-xl"
            />
          </div>
        </div>
        <div className="w-[23%] flex items-center justify-between">
          <div className="w-[20%]">
            <ImageTag
              path="/assets/daashboard/price.png"
              classes="w-full h-full"
              altText="tenants"
            />
          </div>
          <div className="w-[75%]">
            <ParagraphTag
              content={rightPanel.pending}
              classes="text-black/40 font-popinsRegular text-sm"
            />
            <ParagraphTag
              content="$25,000"
              classes="text-black font-popinsBold text-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordSection;
