import React from "react";
import ImageTag from "../../reusebale/imageTag";
import db from "../../../db/index.json";
import ParagraphTag from "../../reusebale/Paragraph";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useSelector } from "react-redux"

const LeftSection = ({ onItemClick }) => {
  const { leftPanel } = db;
  const { loginSuccess, userCompleteProfile } = useSelector((state) => state.auth)

  // content={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName} `}
  return (
    <div className="bg-themeColor rounded-2xl w-full flex flex-col items-center py-3 h-full">
      <ImageTag
        path="/assets/logo-white.png"
        classes="w-[60%]"
        altText="logo"
      />
      <div className="w-[85%] mx-auto h-full mt-2">
        <div className="flex justify-between items-center mt-4">
          <div className="w-[20%]">
            <ImageTag
                 path={userCompleteProfile?.personalInformation?.profileImage ? userCompleteProfile?.personalInformation?.profileImage : "/assets/daashboard/profile-complete/user-icon.png"}
              classes="w-[40px] h-[40px] rounded-full"
              altText="logo"
            />
          </div>
          <div className="w-[78%] cursor-pointer" onClick={() => onItemClick('Profile')}>
            <ParagraphTag
              content={leftPanel.account}
              classes="font-popinsMedium text-white text-xs"
            />
            <div className="flex items-center">
              <ParagraphTag
                content={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName} `}
                classes="font-popinsSemiBold text-white text-sm mt-[-2px] pr-1"
              />
              <ChevronDown color="#FFFFFF" size={18} strokeWidth={3} />
            </div>
          </div>
        </div>
        {/* <button className="text-[13px] text-center border-[3px] border-white text-white cursor-pointer font-popinsMedium rounded-md flex justify-center mx-auto py-2 w-full mb-6">
          <Plus size={18} strokeWidth={3} />
          <span className="pl-3">{leftPanel.create} </span>
        </button> */}
        <div className="flex flex-col mt-2">
          {leftPanel.navData.map((item, i) => (
            <div
              key={i}
              className="flex items-center mt-3 cursor-pointer"
              onClick={() => onItemClick(item.name)}
            >
              <div className="w-[90%] flex items-center">
                <img
                  src={item.image}
                  alt={`${item.label} Icon`}
                  className="w-[30px]"
                />
                <ParagraphTag
                  content={item.label}
                  classes="font-popinsSemiBold text-white text-sm pl-3"
                />
              </div>
              <div className="w-[10%] flex justify-end">
                <ChevronRight color="#FFFFFF" size={18} strokeWidth={2} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-[85%] mx-auto flex flex-col justify-end items-start mb-3">
        {leftPanel.footer.map((item, i) => (
          <div
            key={i}
            className="flex items-center mt-3 cursor-pointer"
            onClick={() => onItemClick(item.name)}
          >
            <div className="w-[90%] flex items-center">
              <img
                src={item.image}
                alt={`${item.label} Icon`}
                className="w-[30px]"
              />
              <ParagraphTag
                content={item.label}
                classes="font-popinsSemiBold text-white text-sm pl-3"
              />
            </div>
            <div className="w-[10%] flex justify-end">
              <ChevronRight color="#FFFFFF" size={18} strokeWidth={2} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftSection;
