import React from "react";
import App from "../../../template/AgreementSample";
import ButtonTag from "../../../reusebale/button";
import ParagraphTag from "../../../reusebale/Paragraph";
import ImageTag from "../../../reusebale/imageTag";
import { ArrowBigDown } from "lucide-react";

const Overview = () => {
  return (
    <div className="h-full pt-5">
      <div className="h-[90%] w-[95%] mx-auto flex justify-between pb-5">
        <div className="w-[49%]  border border-[#E8E8E8] rounded-md">
          <div className="h-[80%] overflow-y-scroll panelScroll">
            <App />
          </div>
          <div className="h-[20%]">
            <div className="bg-[#FAF2EC] flex px-5 h-full items-center rounded-lg">
              <div className="w-[50%] flex items-center">
                <ImageTag
                  path="/assets/daashboard/pdf.png"
                  classes="w-[50px] h-[45px]"
                  altText="logo"
                />
                <div className="pl-2">
                  <ParagraphTag
                    content="Signed Leases 2024.pdf"
                    classes="text-darkColor font-popinsSemiBold text-xs"
                  />
                  <ParagraphTag
                    content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec."
                    classes="text-darkColor font-popinsMedium text-[10px] line-clamp-2 pt-[2px]"
                  />
                  <div className="flex pt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <path
                        d="M3.20915 1.01507C3.20915 0.60703 3.54519 0.246997 3.97723 0.246997C4.38526 0.246997 4.7453 0.60703 4.7453 1.01507V1.78314H7.81758V1.01507C7.81758 0.60703 8.15362 0.246997 8.58566 0.246997C8.99369 0.246997 9.35373 0.60703 9.35373 1.01507V1.78314H10.5058C11.1299 1.78314 11.6579 2.31119 11.6579 2.93525V4.08736H0.90494V2.93525C0.90494 2.31119 1.40899 1.78314 2.05705 1.78314H3.20915V1.01507ZM11.6579 4.85543V11.384C11.6579 12.0321 11.1299 12.5361 10.5058 12.5361H2.05705C1.40899 12.5361 0.90494 12.0321 0.90494 11.384V4.85543H11.6579Z"
                        fill="#FF814E"
                      />
                    </svg>
                    <ParagraphTag
                      content="10/09/2023"
                      classes="text-darkColor/40 font-popinsRegular text-xs pl-1"
                    />
                  </div>
                </div>
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <button className="border-2 border-themeColor flex justify-center items-center px-4 h-9 rounded-lg">
                  <ArrowBigDown color="#FF814E" size={20} />
                  <span className="text-themeColor font-popinsRegular text-xs pl-1">
                    Download Document
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[49%] border border-[#E8E8E8] rounded-lg">
          <div className="h-[85%] w-[90%] mx-auto pt-4">
            <ParagraphTag
              content="Rent Breakdown"
              classes="text-darkColor font-popinsSemiBold text-base text-center pt-1"
            />
            <div className="flex pt-5 justify-between">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Base Rent"
                  classes="text-darkColor font-popinsSemiBold text-xs"
                />
                <ParagraphTag
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet justo ipsum. Sed accumsan quam vitae est varius fringilla. Pellentesque placerat vestibulum lorem sed porta. Nullam mattis tristique iaculis. Nullam pulvinar sit amet risus pretium auctor. Etiam quis massa pulvinar, aliquam quam vitae, tempus sem. Donec elementum pulvinar odio."
                  classes="text-darkColor/40 font-popinsRegular text-xs line-clamp-1"
                />
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <ParagraphTag
                  content="$1,299.99"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="flex pt-3 justify-between">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Security Deposit"
                  classes="text-darkColor font-popinsSemiBold text-xs"
                />
                <ParagraphTag
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet justo ipsum. Sed accumsan quam vitae est varius fringilla. Pellentesque placerat vestibulum lorem sed porta. Nullam mattis tristique iaculis. Nullam pulvinar sit amet risus pretium auctor. Etiam quis massa pulvinar, aliquam quam vitae, tempus sem. Donec elementum pulvinar odio."
                  classes="text-darkColor/40 font-popinsRegular text-xs line-clamp-1"
                />
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <ParagraphTag
                  content="$299.99"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="flex pt-3 justify-between">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Maintenance Charges"
                  classes="text-darkColor font-popinsSemiBold text-xs"
                />
                <ParagraphTag
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet justo ipsum. Sed accumsan quam vitae est varius fringilla. Pellentesque placerat vestibulum lorem sed porta. Nullam mattis tristique iaculis. Nullam pulvinar sit amet risus pretium auctor. Etiam quis massa pulvinar, aliquam quam vitae, tempus sem. Donec elementum pulvinar odio."
                  classes="text-darkColor/40 font-popinsRegular text-xs line-clamp-1"
                />
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <ParagraphTag
                  content="$49.99"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
            <div className="flex pt-3 justify-between">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Cable Charges"
                  classes="text-darkColor font-popinsSemiBold text-xs"
                />
                <ParagraphTag
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet justo ipsum. Sed accumsan quam vitae est varius fringilla. Pellentesque placerat vestibulum lorem sed porta. Nullam mattis tristique iaculis. Nullam pulvinar sit amet risus pretium auctor. Etiam quis massa pulvinar, aliquam quam vitae, tempus sem. Donec elementum pulvinar odio."
                  classes="text-darkColor/40 font-popinsRegular text-xs line-clamp-1"
                />
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <ParagraphTag
                  content="$49.99"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
          </div>
          <div className="h-[15%] border-t border-darkColor/10 w-[90%] mx-auto">
            {/* <div className="h-[1px] bg-darkColor/10 w-full mt-20"></div> */}
            <div className="flex py-5 justify-between">
              <div className="w-[50%]">
                <ParagraphTag
                  content="Total Amount"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
              <div className="w-[50%] flex justify-end items-center">
                <ParagraphTag
                  content="$1598.97"
                  classes="text-darkColor font-popinsSemiBold text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            name="Submit"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
