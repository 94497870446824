import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";
import {ChevronDown} from 'lucide-react'
import ApexChart from './dashborad-charts/pieChart'

const OccupancyRate = () => {
  const { rightPanel } = db;

  return (
    <div className="bg-white rounded-2xl pt-5 px-4 w-full mt-5 flex shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
      <div className="w-[50%]">
        <ParagraphTag
          content={rightPanel.occupancy}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
        <div className="w-full relative group mt-4">
          <div className="bg-[#F4F4F4] flex px-3 py-3 justify-between items-center rounded-xl cursor-pointer">
            <p className="text-darkColor font-popinsRegular text-sm">612 Shadowmar Drive</p>
            <div className="flex justify-center items-center bg-themeColor px-[6px] py-1 rounded-md cursor-pointer">
                <ChevronDown color="#FFFFFF" size={18} strokeWidth={3}/>
            </div>
          </div>
          <div className="bg-white shadow-xl py-3 hidden group-hover:flex group-hover:flex-col border border-white/20 rounded-xl w-full right-0 top-[53px] absolute">
            {rightPanel.rateDropdown.map((item, i) => (
              <p className="text-darkColor font-popinsRegular cursor-pointer text-sm pt-4 pl-4 border-b-[1px] border-darkColor/20 pb-2">{item.value}</p>
            ))}
          </div>
        </div>
        
        <div className="flex pt-8">
          <div className="w-[50%]">
            <ParagraphTag
              content={rightPanel.vacant}
              classes="text-darkColor/40 font-popinsMedium text-base"
            />
            <ParagraphTag
              content="17%"
              classes="text-darkColor font-popinsBold text-[22px]"
            />
          </div>
          <div className="w-[50%]">
            <ParagraphTag
              content={rightPanel.occupied}
              classes="text-darkColor/40 font-popinsMedium text-base"
            />
            <ParagraphTag
              content="83%"
              classes="text-darkColor font-popinsBold text-[22px]"
            />
          </div>
        </div>
      </div>
      <div className="w-[50%] flex justify-center">
        <ApexChart />
      </div>
    </div>
  );
};

export default OccupancyRate;
