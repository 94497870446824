import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph"
import ImageTag from "../../../reusebale/imageTag"

const TenantInfo = () => {
  return (
    <div className="bg-white rounded-xl">
      <div className="px-4 pt-4">
        <ParagraphTag
          content="Tenant Info"
          classes="text-darkColor font-popinsSemiBold text-lg mt-[-3px]"
        />
      </div>
      <div className="flex mt-1 items-center">
        <div className="w-[30%]">
          <ImageTag
            path="/assets/daashboard/profile.png"
            classes="w-[230px] rounded-full"
            altText="logo"
          />
        </div>
        <div className="w-[70%]">
          <div className="pt-3">
            <ParagraphTag
              content="Name"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content="John Doe"
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2">
            <ParagraphTag
              content="Email Address"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content="John Doe123@email.com"
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
          <div className="pt-2">
            <ParagraphTag
              content="Phone"
              classes="text-darkColor font-popinsSemiBold text-sm"
            />
            <ParagraphTag
              content="+4256932997"
              classes="text-darkColor/40 font-popinsRegular mt-[-2px] text-xs "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantInfo;
