import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph"
import ImageTag from '../../../reusebale/imageTag'


const OverviewSection = ({ allDetails }) => {


  return (
    <div className="flex justify-between mt-5">
      <div className="w-[59%] bg-white rounded-lg">
        <div className="px-5">
          <ParagraphTag
            content='Property Overview'
            classes={`text-darkColor font-popinsSemiBold pl-3 pt-4 text-lg`}
          />
          <div className="flex justify-between mt-9">
            <div className="w-[40%] 2xl:w-[35%]">
              <ImageTag classes="w-[180px] h-[180px] 2xl:w-[200px] 2xl:h-[200px] rounded-full" path={allDetails?.thumbnailImage?.url} altText='logo' />
            </div>
            <div className="w-[59%] 2xl:w-[64%] px-2 py-2">
              <div>
                <ParagraphTag
                  content='Complete Address'
                  classes={`text-darkColor font-popinsSemiBold text-sm `}
                />

                <ParagraphTag
                  content={allDetails?.completeAddress}
                  classes={`text-darkColor/40 font-popinsregular text-xs `}
                />
              </div>
              <div className="flex mt-3">
                <div className="w-[50%] pr-1">
                  <ParagraphTag
                    content='Street 1'
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />
                  <ParagraphTag
                    content={allDetails?.streetOne}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
                <div className="w-[50%] pl-1">
                  <ParagraphTag
                    content='Street 2'
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />

                  <ParagraphTag
                    content={allDetails?.streetTwo}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div className="w-[50%]">
                  <ParagraphTag
                    content='City'
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />

                  <ParagraphTag
                    content={allDetails?.city}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
                <div className="w-[50%]">
                  <ParagraphTag
                    content='Country'
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />

                  <ParagraphTag
                    content={allDetails?.country}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
              </div>
              <div className="flex mt-3">
                <div className="w-[50%]">
                  <ParagraphTag
                    content='State'
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />

                  <ParagraphTag
                    content={allDetails?.state}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
                <div className="w-[50%]">
                  <ParagraphTag
                    content='Zip Code'
                    classes={`text-darkColor font-popinsSemiBold text-sm `}
                  />

                  <ParagraphTag
                    content={allDetails?.zipCode}
                    classes={`text-darkColor/40 font-popinsregular text-xs `}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[39%]">
        <ImageTag path={'/assets/daashboard/map-properties.png'} altText='logo' />
      </div>
    </div>
  );
};

export default OverviewSection;
