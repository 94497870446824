import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class ApexChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [0], // Start at 0 for animation
      options: {
        chart: {
          height: 250,
          type: "radialBar",
          animations: {
            enabled: false, // Disable internal animation (we'll handle it manually)
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%", // Hollow center size
            },
            track: {
              background: "#e7e7e7", // Background track color
              strokeWidth: "100%", // Track width
              dropShadow: {
                enabled: true, // Enable shadow for depth
                top: 1, // Vertical shadow offset (reduced)
                left: 1, // Horizontal shadow offset (reduced)
                blur: 4, // Blur radius for smoother shadow (reduced)
                opacity: 0.1, // Shadow transparency (reduced)
              },
            },
            dataLabels: {
              name: {
                show: true,
                color: "rgba(0, 0, 0, 0.3)", // Black color with 40% opacity
                fontSize: "14px",
                fontWeight: "600",
                offsetY: 20, // Push the name text downward
              },
              value: {
                show: true,
                color: "rgba(0, 0, 0)", // Black color with 40% opacity
                fontSize: "22px",
                fontWeight: "700",
                offsetY: -20, // Pull the percentage upwards
              },
            },
            startAngle: -90,
            endAngle: 270,
            strokeLinecap: "round", // Rounded ends
          },
        },
        fill: {
          colors: ["#5267ED"], // Solid uniform color for the radial bar
        },
        labels: ["Vacant"],
      },
    };
  }

  componentDidMount() {
    // Animation function to update the series value gradually
    this.animateCounter(70); // Target value
  }

  animateCounter(targetValue) {
    let currentValue = 0;
    const increment = targetValue / 60; // Increment step for smooth animation
    const interval = setInterval(() => {
      if (currentValue >= targetValue) {
        clearInterval(interval);
      } else {
        currentValue += increment;
        this.setState({ series: [Math.round(currentValue)] }); // Update the series value
      }
    }, 20); // Adjust interval time for smoother transitions
  }

  render() {
    return (
      <div>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={230}
        />
      </div>
    );
  }
}

export default ApexChart;
