import React from "react";


const App = () => {
  const RentalPropertyAgreement = ({
    tenantName,
    landlordName,
    unitName,
    propertyAddress,
    rent,
    securityDeposit,
    additionalCharges,
    lateFees,
    leaseStartDate,
    leaseEndDate,
    details,
  }) => {
    return (
      <div className="w-[8.27in] h-[11.69in] mx-auto p-8 bg-white">
        <h1 className="text-xl text-darkColor font-popinsBold text-center mb-6 border-b-2 border-darkColor">Rental Property Lease Agreement</h1>

        <p className="mb-4 text-sm text-gray-700">
          This Rental Lease Agreement (“Agreement”) is entered into on this <strong>{new Date().toLocaleDateString()}</strong>, by and between <strong>{landlordName}</strong> (“Landlord”) and
          <strong> {tenantName}</strong> (“Tenant”). This Agreement is legally binding under the laws of the United States of America.
        </p>

        {/* Section 1: Property Details */}
        <h2 className="text-lg font-semibold mt-6 mb-2">1. Property Details</h2>
        <p className="text-sm text-gray-700">
          The Landlord rents to the Tenant the property located at:
        </p>
        <p className="font-semibold">{propertyAddress}</p>
        <ul className="text-sm text-gray-700 mt-2">
          <li><strong>Unit Name:</strong> {unitName}</li>
          <li><strong>Rent:</strong> ${rent} per month</li>
          <li><strong>Security Deposit:</strong> ${securityDeposit}</li>
          <li><strong>Additional Charges:</strong> ${additionalCharges}</li>
          <li><strong>Late Fees:</strong> ${lateFees} (applies after 5 days of late payment)</li>
        </ul>

        {/* Section 2: Lease Term */}
        <h2 className="text-lg font-semibold mt-6 mb-2">2. Lease Term</h2>
        <p className="text-sm text-gray-700">
          The lease will begin on <strong>{leaseStartDate}</strong> and will end on <strong>{leaseEndDate}</strong>. If the Tenant wishes to extend the lease, they must provide written notice at least 30 days before the lease end date.
        </p>

        {/* Section 3: Tenant Responsibilities */}
        <h2 className="text-lg font-semibold mt-6 mb-2">3. Tenant Responsibilities</h2>
        <p className="text-sm text-gray-700">
          The Tenant agrees to:
        </p>
        <ul className="list-disc pl-6 text-sm text-gray-700">
          <li>Pay rent on or before the due date each month.</li>
          <li>Maintain the property in a clean and habitable condition.</li>
          <li>Not sublease the property without prior written consent from the Landlord.</li>
          <li>Comply with all local, state, and federal laws.</li>
        </ul>

        {/* Section 4: Landlord Responsibilities */}
        <h2 className="text-lg font-semibold mt-6 mb-2">4. Landlord Responsibilities</h2>
        <p className="text-sm text-gray-700">
          The Landlord agrees to:
        </p>
        <ul className="list-disc pl-6 text-sm text-gray-700">
          <li>Ensure the property is in good condition before the lease begins.</li>
          <li>Respond promptly to maintenance requests.</li>
          <li>Not interfere with the Tenant's right to quiet enjoyment of the property.</li>
        </ul>

        {/* Section 5: Property Details */}
        <h2 className="text-lg font-semibold mt-6 mb-2">5. Property Specifications</h2>
        <div className="grid grid-cols-2 gap-4">
          {details.map((detail, index) => (
            <div key={index} className="text-sm">
              <p>
                <strong>{detail.label}:</strong> {detail.value}
              </p>
            </div>
          ))}
        </div>

        {/* Section 6: Legal Disclosures */}
        <h2 className="text-lg font-semibold mt-6 mb-2">6. Legal Disclosures</h2>
        <p className="text-sm text-gray-700">
          This Agreement is governed by the laws of the United States and the state in which the property is located. Both parties agree to resolve disputes through mediation or small claims court, as applicable.
        </p>

        {/* Signatures */}
        <div className="mt-8">
          <h2 className="text-lg font-semibold mb-4 text-center">Signatures</h2>
          <div className="flex justify-between">
            <div className="text-center">
              <p>_________________________</p>
              <p className="text-sm text-gray-500">Tenant's Signature</p>
            </div>
            <div className="text-center">
              <p>_________________________</p>
              <p className="text-sm text-gray-500">Landlord's Signature</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const details = [
    { label: "% Office", value: "20%" },
    { label: "Bathrooms in Every Suite", value: "Yes" },
    { label: "HVAC", value: "4" },
    { label: "Avg. Building Depth", value: "100%" },
    { label: "Clear Height", value: "30%" },
    { label: "Parking Spaces / Ratio", value: "2015" },
    { label: "Sprinklered", value: "2.3 Years" },
    { label: "Water", value: "2.2 Acres" },
    { label: "Electric", value: "20%" },
    { label: "Submetered", value: "Yes" },
    { label: "WALT", value: "4" },
    { label: "Acres", value: "100%" },
    { label: "Suites Total", value: "30%" },
    { label: "Loading", value: "2015" },
    { label: "Grade Level Doors", value: "2.3 Years" },
    { label: "Avg Tenant Size", value: "2.2 Acres" },
    { label: "Year Built", value: "2015" },
    { label: "# of Buildings", value: "4" },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <RentalPropertyAgreement
        tenantName="John Doe"
        landlordName="Jane Smith"
        unitName="Unit 101"
        propertyAddress="1234 Elm Street, Springfield"
        rent={1200}
        securityDeposit={2400}
        additionalCharges={100}
        lateFees={50}
        leaseStartDate="01/01/2024"
        leaseEndDate="12/31/2024"
        details={details}
      />
    </div>
  );
};

export default App;
