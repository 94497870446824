import React from "react";
import ParagraphTag from "../../../reusebale/Paragraph";
import ButtonTag from "../../../reusebale/button";

const Overview = () => {
  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="text-center h-[10%]">
        <ParagraphTag
          content="request Overview"
          classes="text-darkColor font-popinsSemiBold text-base 2xl:text-lg"
        />
      </div>
      <div className="overflow-y-scroll h-[80%] panelScroll px-1">
        <div className="w-[65%] mx-auto mt-2">
          <div className="flex justify-between">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Property"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="2323 Dancing Dove Lane, Long Island City, NY 11101"
                    classes={`text-darkColor/40 font-popinsRegular text-xs line-clamp-1`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Unit"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Unit #14"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <ParagraphTag
              content="Description"
              classes={`text-darkColor font-popinsSemiBold text-sm`}
            />
            <div>
              <ParagraphTag
                content="Donec a eros justo. Fusce egestas tristique ultrices. Nam tempor, augue nec tincidunt moles, massa nunc varius arcu, at scelerisque elit erat a magna. Donec quis erat at libero ultrices mollis. In hac habitasse platea dictumst. Vivamus vehicula leo dui, "
                classes={`text-darkColor/40 font-popinsRegular text-xs line-clamp-3`}
              />
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Category"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Lorem Ipsum"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Date"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Oct 26, 2024 12:52 am"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Due Date"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="Oct 27, 2024 5:55 am"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Caller Name"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="184 Griffin Street, Gilbert, AZ 85233"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Caller Email"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="r.m.smith@gmail.com"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Caller Phone"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="(830) 556-6651"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-2">
            <div className=" w-[70%]">
              <div>
                <ParagraphTag
                  content="Tech Note"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="NA"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[29%]">
              <div>
                <ParagraphTag
                  content="Access Note"
                  classes={`text-darkColor font-popinsSemiBold text-sm`}
                />
                <div>
                  <ParagraphTag
                    content="NA"
                    classes={`text-darkColor/40 font-popinsRegular text-xs`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 flex">
            <div className="w-[100px] h-[100px] bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
            </div>
            <div className="w-[100px] h-[100px] mx-2 bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
            </div>
            <div className="w-[100px] h-[100px] bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
            </div>
            <div className="w-[100px] h-[100px] mx-2 bg-cross bg-cover rounded-md flex justify-end pt-2 pr-2">
            </div>
          </div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center">
        <div className="w-[40%] mx-auto mt-4 mb-2">
          <ButtonTag
            name="Submit"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
