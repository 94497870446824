import React from "react";
import ParagraphTag from "../reusebale/Paragraph"
import { ArrowLeft } from "lucide-react";

const Breadcrumbs = (props) => {

    const { onBack, content, classes, subheading } = props;

  return (
    <div className="flex">
        <div className="flex justify-center items-center bg-white w-9 h-9 rounded-full cursor-pointer" onClick={onBack}>
          <ArrowLeft className="text-themeColor" size={20} strokeWidth={2} />
        </div>
        <div className="ml-4">
          <ParagraphTag
            content={subheading}
            classes="text-darkColor/40 font-popinsMedium text-xs "
          />
          <ParagraphTag
            content={content}
            classes={`${classes} text-darkColor font-popinsSemiBold text-lg mt-[-3px]`}
          />
        </div>
    </div>
  );
}

export default Breadcrumbs;
