import React from "react";
import ParagraphTag from "../../reusebale/Paragraph"
import { Star } from "lucide-react";

const ReportCard = (props) => {
    const { item, title } = props;

    return (
        <div className="mt-3">
            <div className="bg-white px-4 py-3 rounded-xl">
                <ParagraphTag
                    content={title}
                    classes="font-popinsSemiBold text-black text-md"
                />
                {item.map((item, i) => (
                    <div className="flex mt-2">
                        <div className="w-[92%]">
                            <ParagraphTag
                                content={item.value}
                                classes="text-darkColor font-popinsRegular text-xs"
                            />
                        </div>
                        <div className="w-[8%] flex justify-end items-center">
                            <Star size={15} className={`${item.isActive ? 'fill-[#FCCA01] stroke-[#FCCA01]' : 'stroke-[#B3B3B3]'} `}/>
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default ReportCard;
