import React from "react";
import db from "../../../../db/index.json";
import ParagraphTag from "../../../reusebale/Paragraph";
import IconCards from "../../../reusebale/iconCards";
import ButtonTag from "../../../reusebale/button";
import { Plus, Trash } from "lucide-react";
import LabelTag from "../../../reusebale/label";

const Deposite = () => {
  const { properties } = db;

  return (
    <div className="w-[70%] mx-auto h-full">
      <div className="h-[10%] text-center">
        <ParagraphTag
          content="Security deposit"
          classes="text-darkColor font-popinsSemiBold text-lg"
        />
        <ParagraphTag
          content="Will you be holding a security deposit on this lease?"
          classes="text-darkColor/50 font-popinsMedium text-xs"
        />
      </div>

      <div className="overflow-y-scroll h-[80%] panelScroll flex flex-col items-center pb-5">
        <div className="flex w-[40%] mx-auto justify-between mt-6">
          {properties.fundsData.map((item) => (
            <div
              key={item.title}
              className="w-[50%] cursor-pointer mx-1 flex flex-col items-center"
            >
              <IconCards
                mainClasses={`h-36 w-[130px] ${
                  item.active ? "border-red-500 border-2" : ""
                }`}
                imgClasses="w-[40px]"
                path={item.active ? item.imagePathActiv : item.imagePath}
                title={item.title}
                titleClasses={
                  item.active ? "text-themeColor" : "text-darkColor"
                }
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between">
          <div className="w-[49%] mt-4">
            <LabelTag
              isStaric={false}
              name="Last date to submit"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="date"
              placeholder="Type"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[49%] mt-4">
            <LabelTag
              isStaric={false}
              name="Deposit amount"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="text"
              placeholder="Type"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
        </div>

       
        <div className="w-full flex justify-between mt-12">
          <div className="w-[5%] flex items-center pt-4">
            <ParagraphTag
              content="1"
              classes={`text-themeColor font-popinsSemiBold pt-1 text-xs`}
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="What’s this for?"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Description"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="w-[28%]">
            <LabelTag
              isStaric={false}
              name="Amount"
              classes="!text-xs !font-popinsMedium"
            />
            <input
              disabled
              type="text"
              placeholder="Type here"
              className="w-full mt-1 text-sm bg-bgColor font-popinsRegular px-3 py-2 rounded-sm text-darkColor placeholder:text-zinc-700/50"
            />
          </div>
          <div className="flex justify-end items-end pb-1 cursor-pointer w-[6%]">
            <div className="border-2 border-dangerColor w-7 h-7 rounded-md flex justify-center items-center">
              <Trash color="#E43B3B" size={16} />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between items-center pt-6">
          <div className="w-[36%] h-[1px] bg-[#B3B3B3]"></div>
          <div className="w-[25%]">
            <button className="flex justify-center items-center text-themeColor rounded-md border border-themeColor py-2 w-full text-xs font-popinsMedium">
              <Plus size={15} color="#FF814E" />
              <span className="pl-2">Add New Charge</span>
            </button>
          </div>
          <div className="w-[36%] h-[1px] bg-[#B3B3B3]"></div>
        </div>
      </div>
      <div className="h-[10%] flex justify-center items-center">
        <div className="w-[50%]">
          <ButtonTag
            name="Next"
            classes="text-base text-center bg-themeColor text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default Deposite;
