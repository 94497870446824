import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import db from "../../db/index.json";
import LabelTag from "../reusebale/label";
import ButtonTag from "../reusebale/button";
import { EyeOff, Eye } from "lucide-react";
import MyLoader from "../reusebale/myLoader";
import { useSelector, useDispatch } from "react-redux"
import { ShowError, ShowSuccess } from "../../utils/ToasFunction";

import * as AuthAction from "../../store/actions/auth/AuthAction"
const FormSection = () => {
  const { loader, signUpSuccess, signUpError } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: ""


  })
  const { signUp } = db;

  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  

  const toggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword(!confirmShowPassword);
  };

  const onSignUpHandler = (e) => {
    e.preventDefault();

    if (formFields.email === "") {
      ShowError("Email is required")
    } else if (formFields.firstName === "") {
      ShowError("First name is required")
    } else if (formFields.lastName === "") {
      ShowError("Last name is required")
    } else if (formFields.password === "") {
      ShowError("Password name is required")
    } else if (formFields.confirmPassword === "") {
      ShowError("Confirm password name is required")
    } else if (formFields.password !== formFields.confirmPassword) {
      ShowError("Password not match")
    } else {
      dispatch(AuthAction.UserSignUpAction(formFields))
    }
  }
  const SaveSignUpDataToLocal = (data) => {
    localStorage.setItem('signUpSucc', JSON.stringify(data));
  }

  useEffect(() => {
    if (signUpSuccess) {
      ShowSuccess("Account created successfully")
      SaveSignUpDataToLocal(signUpSuccess)
      navigate("/account-verification")


    }
    if (signUpError) {
      ShowError(signUpError.message)
    }
  }, [signUpError, signUpSuccess])
  return (
    <form>
      <div class="w-full mt-3">
        <LabelTag name={signUp.emailLabel} />
        <input
          value={formFields.email}
          onChange={(e) => {
            setFormFields({
              ...formFields,
              email: e.target.value
            })
          }}
          type="text"
          placeholder="Enter your email.."
          className="w-full font-popinsRegular mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50"
        />
      </div>
      <div className="flex flex-col lg:flex-row justify-between mt-4">
        <div className="w-full lg:w-[49%]">
          <LabelTag name={signUp.firstLabel} />
          <input
            value={formFields.firstName}
            onChange={(e) => {
              setFormFields({
                ...formFields,
                firstName: e.target.value
              })
            }}
            type="text"
            placeholder="Enter your first name here.."
            className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
          />
        </div>
        <div className="w-full lg:w-[49%] mt-4 lg:mt-0">
          <LabelTag name={signUp.lastLabel} />
          <input
            value={formFields.lastName}
            onChange={(e) => {
              setFormFields({
                ...formFields,
                lastName: e.target.value
              })
            }}
            type="text"
            placeholder="Enter your last name here.."
            className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between mt-4">
        <div className="w-full lg:w-[49%]">
          <LabelTag name={signUp.passLabel} />
          <div className="relative">
            <input
              value={formFields.password}
              onChange={(e) => {
                setFormFields({
                  ...formFields,
                  password: e.target.value
                })
              }}
              type={showPassword ? "text" : "password"}
              placeholder="Create password here.."
              className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
            />
            <div
              title="Show password"
              className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <Eye size={20}/> : <EyeOff size={20} />}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[49%] mt-4 lg:mt-0">
          <LabelTag name={signUp.confirmLabel} />
          <div className="relative">
            <input
              value={formFields.confirmPassword}
              onChange={(e) => {
                setFormFields({
                  ...formFields,
                  confirmPassword: e.target.value
                })
              }}
              type={confirmShowPassword ? "text" : "password"}
              placeholder="Confirm password here.."
              className="w-full mt-1 bg-bgColor px-3 py-2 text-sm rounded-lg text-darkColor placeholder:text-zinc-700/50 font-popinsRegular"
            />
            <div
              title="Show password"
              className="absolute right-3 top-3 text-stone-950/40 cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            >
              {confirmShowPassword ? <Eye size={20}/> : <EyeOff size={20} />}
            </div>
          </div>
        </div>
      </div>
      <div >
        {
          loader ? <div className="flex justify-center items-center mt-4">
            <MyLoader />
          </div> : <ButtonTag onSubmit={onSignUpHandler} name={signUp.SubmitBtn} classes='text-base bg-themeColor hover:bg-themeColor/90 mt-5 text-center  text-white' />
        }

      </div>
    </form>
  );
};

export default FormSection;
