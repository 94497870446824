import React from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import db from "../../../db/index.json";

const initialData = [
  { label: "Not Started", count: 50, percentage: 50, color: "#5267ED" },
  { label: "Renewal Offer", count: 15, percentage: 15, color: "#01CB78" },
  { label: "Draft Lease", count: 20, percentage: 20, color: "#E43B3B" },
  { label: "Not Renewing", count: 15, percentage: 15, color: "#F48051" },
];

export default function StatusChart() {
  const { rightPanel } = db;

  return (
    <div className="bg-white rounded-2xl py-5 px-4 w-full mt-5 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">   
      <div className="">
        <ParagraphTag
          content={rightPanel.stats}
          classes="text-darkColor font-popinsSemiBold text-xl"
        />
      </div>
      <div className="flex flex-col items-end">
        <div>
            <ParagraphTag
            content='Next 120 Days'
            classes="text-darkColor/40 font-popinsMedium text-sm"
            />
            <ParagraphTag
            content={`${rightPanel.total} 14`}
            classes="text-darkColor font-popinsBold text-lg"
            />
        </div>  
      </div>
      <div className="flex items-center space-x-4 mt-4">
        <div className="flex flex-col justify-end h-36 w-8">
          {initialData.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: item.color,
                height: `${item.percentage}%`,
              }}
            />
          ))}
        </div>
        <div className="flex-1">
          {initialData.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-3">
              <div className="flex items-center">
                <div
                  style={{ backgroundColor: item.color }}
                  className="w-4 h-4 mr-2 rounded-sm"
                />
                <ParagraphTag
                  content={item.label}
                  classes="mr-4 text-darkColor/40 font-popinsMedium text-sm"
                />
              </div>
              <div className="flex items-center">
                <ParagraphTag
                  content={item.count}
                  classes="mr-4 text-darkColor/40 font-popinsMedium text-sm"
                />
                <ParagraphTag
                  content={`${item.percentage}%`}
                  classes="mr-4 text-darkColor/40 font-popinsMedium text-sm"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
