import React from "react";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import InfoCards from "../../../reusebale/cards";
import LeaseInfo from "./leaseInfo";
import TenantInfo from "./tenantInfo";
import PropertyInfo from "./propertyInfo";
import db from "../../../../db/index.json";
import MaintenanceRequestSection from "../../home/maintainance-request"
import ReceivePayment from "../../home/receivePayment"

const DetailMain = ({ onBack }) => {
  const { lease } = db;

  return (
    <div>
      <div className="mt-6 w-full">
        <Breadcrumbs
          classes={`text-darkColor font-popinsSemiBold text-xl`}
          content="LID-000199581"
          subheading="Lease Details"
          onBack={onBack}
        />
        <LeaseInfo />
        <div className="flex justify-between mt-5">
          <div className="w-[49%]">
            <TenantInfo />
          </div>
          <div className="w-[49%]">
            <PropertyInfo />
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <div className="w-[49%]">
            <MaintenanceRequestSection />
          </div>
          <div className="w-[49%]">
            <ReceivePayment />
          </div>
        </div>
        <div className="flex flex-wrap justify-between mt-5">
          {lease.cardData2.map((item, i) => (
            <InfoCards item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailMain;
