import React, { useEffect } from 'react'
import MyLoader from '../component/reusebale/myLoader'
import { useNavigate } from "react-router-dom"
const Middleware = () => {
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            navigate("/login")
        }, 1000)
    }, [])
    return (
        <div className='flex h-screen justify-center items-center'>
            <MyLoader />
        </div>
    )
}

export default Middleware