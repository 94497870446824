import React from "react";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import CompanyForm from "../company-information/company-form";
import UploadImage from "../company-information/upload-image";
import { useSelector } from "react-redux"


const CompanyInfo = ({ onBack }) => {
  const { loader, loginSuccess, userCompleteProfile } = useSelector((state) => state.auth)
  return (
    <div className="mt-6 w-full">
      <Breadcrumbs content={`${loginSuccess?.user?.firstName} ${loginSuccess?.user?.lastName}`} subheading='My Account' onBack={onBack}/>

      <div className="flex justify-between mt-5">
        <div className="w-[64%]">
          <CompanyForm onBack={onBack}/>
        </div>
        <div className="w-[34%]">
          <UploadImage
            imagePath={userCompleteProfile?.companyInformation?.companyLogo}

          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
