import React, { useState, useEffect } from "react";
import ParagraphTag from "../../reusebale/Paragraph";
import ImageTag from "../../reusebale/imageTag";
import db from "../../../db/index.json";
import ButtonTag from "../../reusebale/button";
import { useSelector, useDispatch } from "react-redux";
import * as AuthAction from "../../../store/actions/auth/AuthAction"
import { ShowSuccess } from "../../../utils/ToasFunction"
import MyLoader from "../../../component/reusebale/myLoader"
// import SuccessPopup from "../../popup/success";

const PlanSection = ({ onButtonClick }) => {
  const { userCompleteProfile, loginSuccess, sendProifleSucc, loader } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const [completeProfileData, setCompleteProfileData] = useState({
    plansData: [],
  });
  const [isApproval, setisApproval] = useState(true);



  // useSelector(() => {
  //   if (sendProifleSucc) {

  //     // dispatch(AuthAction.GetLandlordProfile(loginSuccess?.user?._id))
  //   }
  // }, [sendProifleSucc])

  useEffect(() => {
    const fetchCompleteProfileData = () => {
      const data = db.completeProfileData;
      setCompleteProfileData(data);
    };

    fetchCompleteProfileData();
  }, []);



  useEffect(() => {
    if (userCompleteProfile && completeProfileData.plansData.length > 0) {
      const updatedPlansData = completeProfileData.plansData.map((plan) => {
        if (
          plan.name === "Company Information" &&
          userCompleteProfile?.companyInformation
        ) {
          return { ...plan, isCheck: true }; // Set isCheck to true for Company Information
        }
        if (
          plan.name === "Personal Information" &&
          userCompleteProfile?.personalInformation
        ) {
          return { ...plan, isCheck: true }; // Set isCheck to true for Personal Information
        }
        return plan; // Keep other plans unchanged
      });

      setCompleteProfileData((prevState) => ({
        ...prevState,
        plansData: updatedPlansData,
      }));
    }
  }, [userCompleteProfile, completeProfileData?.plansData]);

  return (
    <div>
      <div className="bg-white rounded-2xl py-5 px-4 w-full mt-6 shadow-md shadow-gray-200 transition-all duration-300 hover:opacity-90">
        {completeProfileData.plansData.map((item, i) => (
          <div
            key={i}
            className="w-[95%] mx-auto mt-3"
            onClick={() => onButtonClick(item.name)}
          >
            <div className="bg-[#E6BF9F1A] hover:border-2 hover:border-themeColor cursor-pointer transition-all duration-100 ease-in-out rounded-lg flex items-center py-2">
              <div className="w-[5%] flex justify-center">
                <ImageTag
                  path="/assets/daashboard/profile-complete/caret-right.png"
                  classes="w-[6px]"
                  altText="caret-right"
                />
              </div>
              <div className="w-[85%] flex items-center">
                <ImageTag
                  path={item.imagePath}
                  classes="w-[40px]"
                  altText="plan-image"
                />
                <ParagraphTag
                  content={item.name}
                  classes="text-darkColor font-popinsSemiBold text-sm pl-3"
                />
              </div>
              <div className="w-[10%] flex justify-center">
                <input
                  type="checkbox"
                  id={`checkbox-${i}`}
                  checked={item.isCheck || false}
                  readOnly
                  className="h-6 w-6 rounded-full border-2 border-statusColor bg-white checked:bg-statusColor checked:border-statusColor focus:ring-statusColor cursor-pointer"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {
        userCompleteProfile?.approvedStatus === "save" && userCompleteProfile.companyInformation && userCompleteProfile.personalInformation &&
        <div className="w-[20%] mt-6">
          {
            loader ? <div className="flex justify-center items-center">
              <MyLoader />
            </div> : <ButtonTag
              onSubmit={(e) => {
                e.preventDefault();

                dispatch(AuthAction.SendProfileForApprovalAction(userCompleteProfile._id))
              }}
              name={completeProfileData.approval}
              classes="text-sm text-center bg-themeColor text-white"
            />
          }


        </div>
      }

      {
        userCompleteProfile?.approvedStatus === "sendForApproval" && <div className=" w-[60%] mx-auto mt-8 flex">
          <ImageTag
            path="/assets/daashboard/profile-complete/status.png"
            classes="w-[70px]"
            altText="login"
          />
          <div className="pl-3">
            <ParagraphTag
              content="Pending Approval"
              classes="text-darkColor font-popinsSemiBold text-xl"
            />
            <ParagraphTag
              content="Your profile has been submitted for review. You will get access to the dashboard as soon as you profile has been approved by the admin."
              classes="text-paraColor font-popinsRegular text-xs mt-1"
            />
          </div>
        </div>
      }

    </div>
  );
};

export default PlanSection;
