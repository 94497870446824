import React, { useState } from "react";
import SearchBar from "../../reusebale/searchBar";
import BroadCastSection from "./broadCastSection";
import CreateBroadCast from "./createBroadCast/main";
import BroadCastDetail from "./broadcastDetail/main";

import db from "../../../db/index.json";

// onClick={onBack}

const BroadCast = ({ onBack }) => {
  const { broadcasts } = db;
  const [showTable, setShowTable] = useState("table");

  const toggleDetail = () => {
    setShowTable("detail"); // State toggle karein
  };

  const toggleCreate = () => {
    setShowTable("create"); // State toggle karein
  };

  const toggleTable = () => {
    setShowTable("table"); // State toggle karein
  };

  return (
    <div>
      {(() => {
        switch (showTable) {
          case "table":
            return (
              <div>
                <SearchBar title={broadcasts.title} desc={broadcasts.subTitle} />
                <BroadCastSection onViewDetails={toggleDetail}
                  onViewCreate={toggleCreate}/>
              </div>
            );
          case "detail":
            return <BroadCastDetail onBack={toggleTable} />;
          case "create":
            return <CreateBroadCast />;
          default:
            break;
        }
      })()}
      
    </div>
  );
};

export default BroadCast;
