import React from "react";

const CreateBroadCast = ({ onBack }) => {


  return (
    <div >
     
    </div>
  );
};

export default CreateBroadCast;
