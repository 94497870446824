import React from "react";
import InfoSection from "../tenantsDetail/info";
import LeaseSection from "../tenantsDetail/leaseSection";
import MaintainanceSection from "../tenantsDetail/maintainance";
import Breadcrumbs from "../../../reusebale/breadcrumbs"
import InfoCards from "../../../reusebale/cards"
import db from "../../../../db/index.json";


const MainSection = ({onBack}) => {
  const { tenants } = db;

  return (
    <div className="mt-6">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        content="Lorri Warf"
        subheading="Tenant Info"
        onBack={onBack}
      />
      <InfoSection />
      <LeaseSection />
      <MaintainanceSection />
      <div className="flex flex-wrap my-5" id="gk-cards">
        {tenants.cardData2.map((item, i) => (
          <InfoCards item={item} classes="mr-[2%]"/>
        ))}
      </div>
    </div>
  );
};

export default MainSection;
