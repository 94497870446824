import React from "react";
import Breadcrumbs from "../../../reusebale/breadcrumbs";
import InfoSection from "../../broadcast/broadcastDetail/infoSection";
import Recipent from "../../broadcast/broadcastDetail/recipent";

const BroadCastDetail = ({ onBack }) => {
  return (
    <div className="mt-4">
      <Breadcrumbs
        classes={`text-darkColor font-popinsSemiBold text-xl`}
        subheading="Broadcasts Details"
        content="LID-000199581"
        onBack={onBack}
      />
      <InfoSection/>
      <Recipent/>
      
      
    </div>
  );
};

export default BroadCastDetail;
